import React from 'react';
import Linkify from 'linkifyjs/react';
import { trimAsString } from '../helpers/String';
import { insertDelimiters } from '../helpers/Array';
import classNames from 'classnames';

import './TextBubble.scss';

class TextBubble extends React.Component {
  render() {
    const { isIncoming, isHighlighted } = this.props;
    const text = trimAsString(this.props.text);

    if (!text)
      return null;

    const linkifyOptions = {
        className: ''
      , attributes: {
        rel: 'noopener noreferrer'
      }
    };

    const lines = text.split(/(?:\r\n|\r|\n)/g);

    return (
      <Linkify
        tagName="div"
        className={classNames('text-bubble ' + (isIncoming ? 'in' : 'out'), { 'highlighted': isHighlighted })}
        options={linkifyOptions}>
        {insertDelimiters(lines, (<br/>))}
      </Linkify>
    );
  }
}

export default TextBubble;