import loadImage from "../helpers/loadImage";
import { isDebug } from '../Constants';

function scaleImg(img, targetWidth, targetHeight, mimeType, quality, complete) {
  const canvas = document.createElement('canvas');

  canvas.width = targetWidth;
  canvas.height = targetHeight;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

  canvas.toBlob(complete, mimeType, quality);
}

function resizeIsSupported(mimeType) {
  const supportedMimeTypes = [
      'image/jpeg'
    , 'image/bmp'
    , 'image/png'
  ];

  return supportedMimeTypes.indexOf(mimeType) >= 0;
}

function resizeImage(file, minFileSize, maxFileSize) {
  return new Promise((resolve, reject) => {
    loadImage(file, ({ img, dispose, width: srcWidth, height: srcHeight }) => {
      if (!img) {
        reject();
        return;
      }

      const mimeType = file.type;

      let scale = maxFileSize / file.size;
      let scaleStep = 0.05;
      const scalesTried = [];

      const maxIterations = 60;
      let i = 0;

      if (isDebug)
        console.log('resizeImage', mimeType);

      const iterate = () => {
        const targetWidth = Math.floor(srcWidth * scale);
        const targetHeight = Math.floor(srcHeight * scale);

        scaleImg(img, targetWidth, targetHeight, mimeType, 0.9, (blob) => {
          scalesTried.push(scale);

          let complete = false;
          let increment = false;

          if (isDebug)
            console.log({ iteration: i, scale, size: blob.size / 1000000 });

          if (blob.size > maxFileSize) {

          }
          else if (blob.size >= minFileSize) {
            complete = true;
          }
          else {
            increment = true;
          }

          if (complete) {
            dispose();

            resolve({
                blob
              , width: targetWidth
              , height: targetHeight
            });

            return;
          }

          i++;

          if (i > maxIterations) {
            reject();
            return;
          }

          let nextScale = scale + (increment ? 1 : -1) * scaleStep;

          if (scalesTried.indexOf(nextScale) >= 0) {
            scaleStep /= 2;

            for (; scalesTried.indexOf(nextScale) >= 0;) {
              nextScale = scale + (increment ? 1 : -1) * scaleStep;
            }
          }

          scale = nextScale;

          setTimeout(iterate, 150);
        });
      };

      iterate();
    });
  });
}

export { resizeImage, resizeIsSupported };