import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import SpinnerOverlay from  '../components/SpinnerOverlay';
import TextEditor from "../components/TextEditor";
import { trimAsString } from "../helpers/String";
import { bindSetState } from "../helpers/React";
import _ from "lodash/object";

import './TextEditorModal.scss';

class TextEditorModal extends React.Component {
  constructor(props) {
    super(props);

    const initialState = {
        processing: false
      , error: null
      , textError: null
    };

    this.state = _.assign({}, initialState);

    this.textEditor = React.createRef();

    this.hidden = bindSetState(this, initialState);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.hide = this.hide.bind(this);
    this.shown = this.shown.bind(this);
    this.textChanged = this.textChanged.bind(this);
  }

  hide() {
    if (!this.state.processing)
      this.props.hide();
  }

  shown() {
    this.textEditor.current.moveCursorToEnd();
    this.textEditor.current.focus();
  }

  validate(text) {
    const { emptyError, validate } = this.props;

    text = trimAsString(text);

    const textError = !text.length
        ? emptyError
        : (validate && validate(text));

    this.setState({
      textError
    });

    return textError;
  }

  textChanged(text) {
    this.validate(text);
  }

  process(action, validate, failureText) {
    const { processing, textError, error } = this.state;

    if (processing || textError || error)
      return;

    const { text } = this.props.textDraft;

    if (validate && this.validate(text))
      return;

    this.setState({
        processing: true
      , error: null
    });

    action()
      .then(() => {
        this.props.hide();
      })
      .catch(() => {
        this.setState({
            processing: false
          , error: failureText
        });
      });
  }

  save() {
    const { save, saveError } = this.props;

    this.process(save, true, saveError);
  }

  remove() {
    const { remove, removeError } = this.props;

    this.process(remove, false, removeError);
  }

  render() {
    const { shown, textDraft, remove, title, saveBtnTitle } = this.props;
    const { processing, textError, error } = this.state;

    return (
      <Modal isOpen={shown} toggle={this.hide} onOpened={this.shown} onClosed={this.hidden} modalClassName="text-editor-modal">
        <ModalHeader toggle={this.hide}>{title}</ModalHeader>
        <ModalBody>
          {error && (<Alert color="danger">{error}</Alert>)}

          <TextEditor
            ref={this.textEditor}
            textDraft={textDraft}
            isInvalid={textError}
            changed={this.textChanged}
            enterKeyPressed={this.save}
          />
          {textError && (<div className="input-error">{textError}</div>)}

          {processing && (<SpinnerOverlay />)}
        </ModalBody>
        <ModalFooter>
          {remove && <Button color="danger" className="remove-btn" onClick={this.remove} disabled={processing || textError || error}>Remove</Button>}
          <Button color="primary" onClick={this.save} disabled={processing || textError || error}>{saveBtnTitle || 'Save'}</Button>{' '}
          <Button color="secondary" onClick={this.hide} disabled={processing}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default TextEditorModal;