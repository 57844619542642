import React from 'react';
import { observer } from 'mobx-react';
import { motion, AnimatePresence } from "framer-motion";
import Spinner from "./Spinner";
import XIcon from 'react-feather/dist/icons/x';
import classNames from 'classnames';

import './ContactListItemView.scss';

class ContactListItemView extends React.Component {
  static animProps = {
    initial: {
        x: -10
      , opacity: 0
    }
    , animate: {
        x: 0
      , opacity: 1
    }
    , exit: {
        x: -2
      , opacity: 0
    }
    , transition: { duration: 0.2 }
  };

  constructor(props) {
    super(props);

    this.state = {
      processingArchived: false
    };

    this.clicked = this.clicked.bind(this);
    this.toggleArchived = this.toggleArchived.bind(this);
  }

  clicked() {
    if (this.props.selected)
      this.props.selected(this.props.combinedContact);
  }

  toggleArchived(event) {
    event.stopPropagation();

    if (this.state.processingArchived)
      return;

    this.setState({
      processingArchived: true
    });

    const complete = () => {
      this.setState({
        processingArchived: false
      });
    };

    this.props.combinedContact.toggleArchived(this.props.session.auth.token)
      .then(complete)
      .catch(complete);
  }

  render() {
    const { combinedContact: { name, phoneNumber, autoReply, unreadTexts }, isActive } = this.props;
    const { processingArchived } = this.state;

    const unreadTextsCount = unreadTexts.incomingCount;

    let cls = classNames('contact-list-item', {
        'active': isActive
      , 'has-unread-texts': unreadTextsCount
    });

    return (
      <div className={cls} onClick={this.clicked}>
        <div className="name">
          <AnimatePresence>
            {unreadTextsCount
              ? (<motion.div key="unread-texts" className="unread-texts" {...ContactListItemView.animProps}>{unreadTextsCount}</motion.div>)
              : null}
          </AnimatePresence>
          {name}
        </div>
        <div className="phone-number">
          {phoneNumber}
          {autoReply && <div className="auto-reply-badge">Auto</div>}
        </div>
        {unreadTextsCount
          ? null
          : (processingArchived ? <Spinner size="20" /> : <XIcon size="20" onClick={this.toggleArchived}/>)}
      </div>
    );
  }
}

export default observer(ContactListItemView);