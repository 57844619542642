import DataObject from "./DataObject";
import { executeRequest } from '../core/Request.js';
import { combineUrlParts } from '../helpers/Url';
import { trimAsString } from "../helpers/String";

class TextTemplate extends DataObject {
  static requestBase = 'templates';

  constructor({ id, text } = {}) {
    super();

    this.id = id;
    this.text = text;
  }

  static fromRawData({ id, message }) {
    return new TextTemplate({
        id: +id
      , text: message
    });
  }

  static add(token, { text }) {
    const data = {
      message: trimAsString(text)
    };

    return executeRequest({
        method: 'post'
      , request: this.requestBase
      , token
      , data
      , responseDataClass: this
    });
  }

  remove(token) {
    return executeRequest({
        method: 'delete'
      , request: combineUrlParts(TextTemplate.requestBase, this.id)
      , token
    });
  }
}

export default TextTemplate;