import React, { Component } from 'react';
import UnauthorizedScreen from './screens/UnauthorizedScreen';
import AuthorizedScreen from './screens/AuthorizedScreen';
import { motion, AnimatePresence } from "framer-motion";

class App extends Component {
  static animProps = {
    initial: {
      opacity: 0
    }
    , animate: {
      opacity: 1
    }
    , exit: {
      opacity: 0
    }
    , transition: { duration: 0.2 }
  };

  constructor(props) {
    super(props);

    this.state = {
        authorized: false
      , session: null
    };

    this.authorized = this.authorized.bind(this);
    this.unauthorized = this.unauthorized.bind(this);
  }

  authorized(session) {
    this.setState({
        authorized: true
      , session
    });
  }

  unauthorized() {
    this.setState({
        authorized: false
      , session: null
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.session && (prevState.session !== this.state.session))
      prevState.session.combinedContactList.destroy();
  }

  render() {
    const authorized = this.state.authorized;

    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div key={authorized ? "authorized" : "unauthorized"} className="h-100" {...App.animProps}>
          {authorized
            ? <AuthorizedScreen unauthorized={this.unauthorized} session={this.state.session} />
            : <UnauthorizedScreen authorized={this.authorized} />}
        </motion.div>
      </AnimatePresence>
    );
  }
}

export default App;