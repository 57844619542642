function loadImage(fileOrBlob, complete) {
  const url = URL.createObjectURL(fileOrBlob);
  const img = new Image();

  const dispose = () => {
    img.src = '';
    URL.revokeObjectURL(url);
  };

  img.onload = () => {
    complete({ img, dispose, width: img.naturalWidth, height: img.naturalHeight });
  };

  img.onerror = () => {
    dispose();
    complete({});
  };

  img.src = url;
}

export default loadImage;