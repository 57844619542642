import React from 'react';
import { observer } from 'mobx-react';
import CheckmarkIcon from 'react-feather/dist/icons/check';
import XIcon from 'react-feather/dist/icons/x';
import Announcement from "../data/Announcement";
import AnnouncementBaseView from "./AnnouncementBaseView";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { bindSetState } from "../helpers/React";
import _ from "lodash/collection";

import './AnnouncementDetailView.scss';

class AnnouncementDetailView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelModalIsOpen: false
    };

    this.refresh = this.process.bind(this, () => this.props.announcement.refresh(this.props.session.auth.token));
    this.cancel = this.process.bind(this, () => this.props.announcement.cancel(this.props.session.auth.token));

    this.showCancelModal = bindSetState(this, { cancelModalIsOpen: true });
    this.hideCancelModal = bindSetState(this, { cancelModalIsOpen: false });

    this.cancelModalAccepted = this.cancelModalAccepted.bind(this);
  }

  process(action) {
    if (this.props.announcement.isProcessing)
      return;

    action();
  }

  cancelModalAccepted() {
    this.hideCancelModal();
    this.cancel();
  }

  render() {
    const { cancelModalIsOpen } = this.state;
    const { announcement } = this.props;
    const { state, phoneNumbers, failedPhoneNumbers } = announcement;
    const { states } = Announcement;
    const isScheduled = state === states.scheduled;
    const isSending = state === states.sending;

    const getPhoneNumberState = (isScheduled || isSending || (state === states.canceled))
      ? () => null
      : (phoneNumber) => <td>{_.includes(failedPhoneNumbers, phoneNumber)
        ? <XIcon size="16" className="failure" />
        : <CheckmarkIcon size="16" className="success" />}</td>;

    return (
      <AnnouncementBaseView announcement={announcement} className="announcement-detail">
        {(isScheduled || isSending) && <div className="buttons-container">
          {(isScheduled || isSending) && <Button outline color="primary" size="sm" onClick={this.refresh}>Refresh</Button>}
          {isScheduled && <Button outline color="danger" size="sm" onClick={this.showCancelModal}>Cancel</Button>}
        </div>}

        <div className="recipients-title">Recipients ({phoneNumbers.length}):</div>
        <table className="recipients">
          {phoneNumbers.map(phoneNumber =>
            <tr>
              <td>{phoneNumber}</td>
              {getPhoneNumberState(phoneNumber)}
            </tr>)}
        </table>

        <Modal isOpen={cancelModalIsOpen} toggle={this.hideCancelModal}>
          <ModalHeader toggle={this.hideCancelModal}>Cancel Announcement</ModalHeader>
          <ModalBody>
            Do you really want to cancel this announcement?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.cancelModalAccepted}>Cancel Announcement</Button>
            <Button color="secondary" onClick={this.hideCancelModal}>Close</Button>
          </ModalFooter>
        </Modal>
      </AnnouncementBaseView>
    );
  }
}

export default observer(AnnouncementDetailView);