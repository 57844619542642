import { observable, decorate } from "mobx";
import Announcement from "../data/Announcement";

class AnnouncementList {
  constructor(announcements) {
    this.announcements = announcements.slice(0);
  }

  add(announcement) {
    this.announcements.push(announcement);
  }

  addToTheTop(announcement) {
    this.announcements.splice(0, 0, announcement);
  }

  static fetch(token) {
    return Announcement.fetchAll(token).then(announcements => new AnnouncementList(announcements.reverse()));
  }

  refresh(token) {
    const { states } = Announcement;

    this.announcements.forEach(announcement => {
      const { isProcessing, state } = announcement;

      if (!isProcessing && ((state === states.scheduled) || (state === states.sending)))
        announcement.refresh(token);
    });
  }
}

decorate(AnnouncementList, {
  announcements: observable
});

export default AnnouncementList;