import DataObject from './DataObject';
import { trimAsString } from '../helpers/String';

class PhoneNumber extends DataObject {
  static requestBase = 'phonenumbers';

  constructor({ id, phoneNumber, active } = {}) {
    super();

    this.id = id;
    this.phoneNumber = phoneNumber;
    this.active = active;
  }

  static fromRawData({ id, number, active }) {
    return new PhoneNumber({
        id: +id
      , phoneNumber: number
      , active: trimAsString(active).length > 0
    });
  }
}

export default PhoneNumber;


