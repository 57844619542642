import React, { Component } from 'react';
import { Tooltip  } from 'reactstrap';
import classNames from 'classnames';

import './TooltipButton.scss';

class TooltipButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipShown: false
    };

    this.button = React.createRef();

    this.clicked = this.clicked.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    this.setState(({ tooltipShown }) => {
      return {
        tooltipShown: !tooltipShown
      };
    });
  }

  clicked() {
    this.setState({
      tooltipShown: false
    });

    const { onClick } = this.props;

    if (onClick)
      onClick();
  }

  render() {
    let { tooltipPlacement, className, tooltip } = this.props;
    if (!tooltipPlacement)
      tooltipPlacement = 'bottom';

    const { tooltipShown } = this.state;

    return (
      <>
        <div className={classNames('tooltip-button', className)} ref={this.button} onClick={this.clicked}>
          {this.props.children}
        </div>
        {tooltip && <Tooltip
          className="tooltip-button-tooltip"
          delay={{ show: 150 }}
          placement={tooltipPlacement}
          target={this.button}
          isOpen={tooltipShown}
          toggle={this.toggleTooltip}>
          {tooltip}
        </Tooltip>}
      </>
    );
  }
}

export default TooltipButton;