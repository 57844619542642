import React from 'react';
import InputFiles from 'react-input-files';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import SpinnerOverlay from '../components/SpinnerOverlay';
import _ from "lodash/collection";
import Papa from "papaparse";
import FileIcon from 'react-feather/dist/icons/file-text';
import AlertIcon from 'react-feather/dist/icons/alert-triangle';
import CheckmarkIcon from 'react-feather/dist/icons/check';
import XIcon from 'react-feather/dist/icons/x-circle';
import { Scrollbars } from 'react-custom-scrollbars';
import Contact from "../data/Contact";
import { bindSetState } from "../helpers/React";
import { stripNonDigits, trimAsString } from "../helpers/String";
import CombinedContact from "../core/CombinedContact";
import _object from "lodash/object";

import './ImportContactsModal.scss';

class ImportContactsModal extends React.Component {
  constructor(props) {
    super(props);

    const initialState = {
        fileSelected: false
      , fileName: ''
      , fileIsEmpty: false
      , fileHasInvalidFormat: false
      , validContactsCount: 0
      , contacts: []
      , importing: false
      , importCompleted: false
      , importedContactsCount: 0
    };

    this.state = _object.assign({}, initialState);

    this.tableScrollbars = React.createRef();

    this.hidden = bindSetState(this, initialState);
    this.hideClicked = this.hideClicked.bind(this);
    this.importClicked = this.importClicked.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
  }

  hideClicked() {
    if (!this.state.importing && this.props.hide)
      this.props.hide();
  }

  validatePhoneNumber(contactData, previousContacts) {
    const { isValid, isEmpty, noDigits, invalidLength, requiredLength, doesNotStartWith1, presentInContactList } =
      Contact.validatePhoneNumber(contactData.phoneNumber, this.props.session.combinedContactList);

    if (!isValid)
      return isEmpty
        ? 'Empty'
        : (noDigits
          ? 'Does not contain digits'
          : (doesNotStartWith1
            ? 'Does not start with "1"'
            : (presentInContactList
              ? 'Already in the contact list'
              : (invalidLength
                ? `${invalidLength} of required ${requiredLength} digits`
                : 'Invalid format'))));

    return (_.find(previousContacts, c => c.processed.phoneNumber === contactData.processed.phoneNumber))
      ? 'Duplicate'
      : null;
  }

  validateName(name) {
    const { isValid, isEmpty, invalidLength, maxLength } = Contact.validateName(name, true);

    return isValid
      ? null
      : (isEmpty
        ? 'Empty'
        : (invalidLength
          ? `${invalidLength} characters - maximum is ${maxLength}`
          : 'Invalid format'));
  }

  vaildateContactData(contactData, previousContacts) {
    const { name } = contactData;

    contactData.errors = {
        phoneNumber: this.validatePhoneNumber(contactData, previousContacts)
      , name: this.validateName(name)
    };

    contactData.isValid = !contactData.errors.phoneNumber && !contactData.errors.name;
  }

  fileSelected(files) {
    if (!files || !files.length)
      return;

    const file = files[0];
    const fileName = file.name;

    Papa.parse(file, {
        skipEmptyLines: 'greedy'
      , complete: ({ data: rows, errors }) => {
        /*
        when there is only one column and it's values do not end with a delimiter
        or when it's an empty file and there are no columns
        PapaParse always reports an 'UndetectableDelimiter' error which we ignore
        */
        errors = _.filter(errors, (error) => error.code !== 'UndetectableDelimiter');

        const state = {
            fileSelected: true
          , fileName
          , validContactsCount: 0
          , fileIsEmpty: false
          , fileHasInvalidFormat: false
          , errors: []
          , contacts: [] //todo
        };

        let callback;

        if (!rows.length && !errors.length) {
          state.fileIsEmpty = true;
        }
        else if (errors.length) {
          state.fileHasInvalidFormat = true;
        }
        else {
          rows.forEach(row => {
            const phoneNumber = row.length ? row[0] : '';
            const name = row.length ? row[1] : '';

            const contact = {
                phoneNumber
              , name
              , processed: {
                  phoneNumber: stripNonDigits(phoneNumber)
                , name: trimAsString(name)
              }
            };

            this.vaildateContactData(contact, state.contacts);

            if (contact.isValid)
              state.validContactsCount++;

            state.contacts.push(contact);
          });

          callback = () => {
            if (this.tableScrollbars)
              this.tableScrollbars.current.scrollToTop();
          };

          state.isReady = true;
        }

        this.setState(state, callback);
      }
    });
  }

  importClicked() {
    const { fileSelected, validContactsCount, contacts, importing } = this.state;

    if (!fileSelected || !validContactsCount || importing)
      return;

    this.setState({
      importing: true
    });

    Contact.addContacts(this.props.session.auth.token, _.filter(contacts, c => c.isValid).map(c => c.processed))
      .then((addedContacts) => {
        if (addedContacts.length) {
          const combinedContacts = addedContacts.map(contact => new CombinedContact({ contact }));

          this.props.session.combinedContactList.addToTheTop(...combinedContacts);

          if (this.props.contactsAdded)
            this.props.contactsAdded(combinedContacts);
        }

        this.setState(({ contacts }) => {
          let importedContactsCount = 0;

          contacts.forEach(contact => {
            if (!contact.isValid || !_.find(addedContacts, addedContact => addedContact.phoneNumber === contact.processed.phoneNumber))
              return;

            contact.isImported = true;
            importedContactsCount++;
          });

          return {
              importing: false
            , importCompleted: true
            , importedContactsCount
            , contacts
          };
        });
      })
      .catch(error => {
        this.setState({
            importing: false
          , importCompleted: true
          , importedContactsCount: 0
        });
      });
  }

  render() {
    const { fileSelected, fileName, validContactsCount, fileIsEmpty, fileHasInvalidFormat, contacts, importing, importCompleted, importedContactsCount } = this.state;

    let content = null;

    if (fileSelected) {
      if (fileIsEmpty)
        content = <p>Nothing to import - the selected file is empty.</p>;
      else if (fileHasInvalidFormat)
        content = <p>The selected file has invalid format.</p>;
      else {
        content = [
          <div className="table-container">
            <div className="table-container-inner">
              <Table bordered>
                <thead>
                  <tr>
                    <th className="phone-number">Phone Number</th>
                    <th className="name">Name</th>
                    <th className="state">Status</th>
                  </tr>
                </thead>
              </Table>
            </div>
          </div>
          , <div className="table-container table-content">
            <Scrollbars ref={this.tableScrollbars}>
              <div className="table-container-inner">
                <Table bordered hover>
                  <tbody>
                    {contacts.map(({ phoneNumber, name, isImported, isValid, errors }) =>
                      <tr>
                        <td className="phone-number">{phoneNumber}{errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}</td>
                        <td className="name">{name}{errors.name && <div className="error">{errors.name}</div>}</td>
                        <td className="state">
                          {!isValid
                            ? (<span className="error"><AlertIcon size="20" />Has errors</span>)
                            : (!importCompleted
                              ? <span><CheckmarkIcon size="20" />Ready to be imported</span>
                              : (isImported
                                ? <span><CheckmarkIcon size="20" />Imported</span>
                                : <span className="error"><XIcon size="20" />Import failed</span>))}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Scrollbars>
          </div>
        ];
      }
    }

    return (
      <Modal isOpen={this.props.shown} toggle={this.hideClicked} onClosed={this.hidden} size="lg" modalClassName="import-contacts-modal">
        <ModalHeader toggle={this.hideClicked}>Import Contacts</ModalHeader>
        <ModalBody>
          <p>The imported CSV file should have two columns: first column should contain the phone number, second column - the name of the contact.</p>

          <p>
            <InputFiles onChange={this.fileSelected} accept=".csv">
              <Button type="button" className="select-file-btn" color="primary" disabled={importCompleted}>
                <FileIcon size="18" />{!fileSelected ? 'Select file' : (fileName || 'File selected')}
              </Button>
            </InputFiles>
          </p>

          {content}

          {importing && (<SpinnerOverlay />)}
        </ModalBody>
        <ModalFooter>
          {fileSelected && !fileIsEmpty && !fileHasInvalidFormat && <div className="stats"><span>Total: {contacts.length}</span><span>Valid: {validContactsCount}</span>{importCompleted && <span>Imported: {importedContactsCount}</span>}</div>}
          {!importCompleted && <Button color="primary" onClick={this.importClicked} disabled={!validContactsCount || importing}>Import{validContactsCount ? ` (${validContactsCount})` : ''}</Button>}{' '}
          <Button color="secondary" onClick={this.hideClicked} disabled={importing}>{importCompleted ? 'OK' : 'Cancel'}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ImportContactsModal;