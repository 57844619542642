import Auth from '../data/Auth';
import PhoneNumber from '../data/PhoneNumber';
import CombinedContactList from './CombinedContactList';
import TextTemplateList from "./TextTemplateList";
import AnnouncementList from "./AnnouncementList";

class Session {
  constructor({ auth, phoneNumber, combinedContactList, textTemplateList, announcementList }) {
    this.auth = auth;
    this.phoneNumber = phoneNumber;
    this.combinedContactList = combinedContactList;
    this.textTemplateList = textTemplateList;
    this.announcementList = announcementList;
  }

  static authorize(credentials) {
    return Auth.login(credentials)
      .then(auth => {
        const { token } = auth;

        return Promise.all([
            PhoneNumber.fetchAll(token)
          , CombinedContactList.fetch(token)
          , TextTemplateList.fetch(token)
          , AnnouncementList.fetch(token)
        ]).then(([ phoneNumbers, combinedContactList, textTemplateList, announcementList ]) => {
          const phoneNumber = phoneNumbers.find(p => p.active);

          return new Session({ auth, phoneNumber, combinedContactList, textTemplateList, announcementList });
        });
      });
  }
}

export default Session;