import { executeListObjectsRequest } from '../core/Request.js';

class DataObject {
  static fetchAll(token) {
    return executeListObjectsRequest({
        request: this.requestBase
      , token
      , responseDataClass: this
    });
  }
}

export default DataObject;