import { decorate, computed, observable } from "mobx";
import OutgoingText from '../data/OutgoingText';
import DialogTexts from '../data/DialogTexts';
import DialogHistory from '../data/DialogHistory';
import UnreadTexts from '../data/UnreadTexts';
import TextDraft from './TextDraft';
import Contact from "../data/Contact";
import { isUndefinedOrNull  } from "../helpers/Object";

/*
CombinedContact combines the Dialog and a Contact objects
*/
class CombinedContact {
  static nextId = 0;

  constructor({ dialog, contact }) {
    if (!dialog && !contact)
      throw new TypeError('CombinedContact: both dialog and contact are not specified');

    this.setDialog(dialog);
    this.newTexts = new DialogTexts();
    this.unreadTexts = new UnreadTexts();
    this.outgoingTextDraft = new TextDraft();

    this.contact = contact;

    this.phoneNumber = dialog ? dialog.contactPhoneNumber : contact.phoneNumber;

    this.generatedId = CombinedContact.nextId++;
  }

  setDialog(dialog) {
    this.dialog = dialog;
    this.dialogHistory = dialog ? new DialogHistory(dialog) : null;
  }

  get name() {
    return (this.contact && this.contact.name) || this.phoneNumber;
  }

  get archived() {
    return this.dialog ? this.dialog.archived : this.contact.archived;
  }

  get autoReply() {
    return this.contact ? this.contact.autoReply : null;
  }

  addPolledText(text) {
    if (!this.unreadTexts.addText(text))
      return false;

    if (!this.dialog)
      this.setDialog(text.dialog);

    if (text.isIncoming && this.dialog.archived)
      this.dialog.archived = false;

    this.newTexts.addText(text);

    return true;
  }

  sameContact(contact) {
    return this.contact && (contact.id === this.contact.id);
  }

  sameDialog(dialog) {
    return this.dialog && (dialog.id === this.dialog.id);
  }

  matches({ dialog, contact }) {
    return dialog && this.dialog
      ? (dialog.id === this.dialog.id)
      : (contact && this.contact && (contact.id === this.contact.id));
  }

  textsWereRead(token) {
    this.unreadTexts.markAsRead(token);
  }

  sendText({ session, text, attachment }) {
    const outgoingText = new OutgoingText({
        from: session.phoneNumber.phoneNumber
      , to: this.phoneNumber
      , text
      , attachment
    });

    outgoingText.send(session.auth.token)
      .then(() => {
        if (!outgoingText.sendError && !this.dialog)
          this.setDialog(outgoingText.dialog);
      });

    this.newTexts.addText(outgoingText);

    //todo move this to a more appropriate place
    session.combinedContactList.moveContactToTop(this);
  }

  toggleArchived(token) {
    return this.dialog
      ? this.dialog.updateArchived(token, !this.dialog.archived)
      : this.contact.updateArchived(token, !this.contact.archived);
  }

  updateName(token, name) {
    let oldName = '';

    if (this.contact && this.contact.name)
      oldName = this.contact.name;

    if (name === oldName)
      return Promise.resolve();

    if (this.contact)
      return this.contact.updateName(token, name);

    return Contact.add(token, { name, phoneNumber: this.phoneNumber })
      .then((contact) => {
        this.contact = contact;
      });
  }

  updateAutoReply(token, autoReply) {
    let oldAutoReply = this.autoReply;

    if ((oldAutoReply === autoReply) || (isUndefinedOrNull(oldAutoReply) && isUndefinedOrNull(autoReply)))
      return Promise.resolve();

    if (this.contact)
      return this.contact.updateAutoReply(token, autoReply);

    return Contact.add(token, { phoneNumber: this.phoneNumber, autoReply })
      .then((contact) => {
        this.contact = contact;
      });
  }

  destroy() {
    if (this.dialogHistory)
      this.dialogHistory.destroy();

    this.newTexts.destroy();
    this.unreadTexts.destroy();
  }

  static destroyCombinedContacts(combinedContacts) {
    combinedContacts.forEach(combinedContact => {
      combinedContact.destroy();
    });
  }
}

decorate(CombinedContact, {
    contact: observable
  , name: computed
  , archived: computed
  , autoReply: computed
});

export default CombinedContact;