import React from 'react';
import { observer } from 'mobx-react';
import TextBubble from "./TextBubble";
import AttachmentView from './AttachmentView';
import { defaultTimestampFormat } from "../Constants";

import './TextView.scss';

class TextView extends React.Component {
  static timestampFormat = defaultTimestampFormat;

  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  getOffsetTop() {
    return this.element.current.offsetTop;
  }

  getHeight() {
    return this.element.current.offsetHeight;
  }

  render() {
    const { text, isHighlighted, auth } = this.props;

    let textClass = 'text ' + (text.isIncoming ? 'in' : 'out');

    const isOutgoing = text.constructor.isOutgoingText;

    let note, noteClassName;

    if (isOutgoing) {
      if (text.sendingCompleted) {
        if (text.sendError) {
          note = 'Failed to send';
          noteClassName = 'send-error';
        }
      }
      else {
        note = 'Sending...';
        noteClassName = 'sending';
      }
    }

    if (!note && text.sentTimestamp) {
      note = text.sentTimestamp.format(TextView.timestampFormat);
      noteClassName = 'timestamp';
    }

    return (
      <div className={textClass} ref={this.element}>
        <TextBubble text={text.text} isIncoming={text.isIncoming} isHighlighted={isHighlighted} />
        {text.attachments && text.attachments.map(attachment =>
          <AttachmentView attachment={attachment} auth={auth}/>)}
        {note && <div className={noteClassName}>{note}</div>}
      </div>
    );
  }
}

export default observer(TextView);