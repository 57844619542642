import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import SpinnerOverlay from  '../components/SpinnerOverlay';
import { bindSetState } from "../helpers/React";
import _ from "lodash/object";

class RemoveTextTemplateModal extends React.Component {
  constructor(props) {
    super(props);

    const initialState = {
        removing: false
      , error: null
    };

    this.state = _.assign({}, initialState);

    this.hidden = bindSetState(this, initialState);
    this.remove = this.remove.bind(this);
    this.hide = this.hide.bind(this);
  }

  hide() {
    if (!this.state.removing)
      this.props.hide();
  }

  remove() {
    const { textTemplateList, auth } = this.props.session;
    const { textTemplate } = this.props;

    this.setState({
        removing: true
      , error: null
    });

    textTemplate.remove(auth.token)
      .then(() => {
        textTemplateList.remove(textTemplate);
        this.props.hide();
      })
      .catch(() => {
        this.setState({
            removing: false
          , error: 'Failed to remove the template.'
        });
      });
  }

  render() {
    const { removing, error } = this.state;
    const { textTemplate } = this.props;

    return (
      <Modal isOpen={this.props.shown} toggle={this.hide} onClosed={this.hidden}>
        <ModalHeader toggle={this.hide}>Remove Template</ModalHeader>
        <ModalBody>
          {error
            ? (<Alert color="danger">{error}</Alert>)
            : (<p>Would you like to remove the template?</p>)}

          <p>{textTemplate ? textTemplate.text : ''}</p>

          {removing && (<SpinnerOverlay />)}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.remove} disabled={removing || error}>Remove</Button>{' '}
          <Button color="secondary" onClick={this.hide} disabled={removing}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default RemoveTextTemplateModal;