import React from 'react';
import AnnouncementBaseView from "./AnnouncementBaseView";
import ArrowRightIcon from 'react-feather/dist/icons/arrow-right-circle';

import './AnnouncementListItemView.scss';

class AnnouncementListItemView extends React.Component {
  constructor(props) {
    super(props);

    this.viewDetailsClicked = this.viewDetailsClicked.bind(this);
  }

  viewDetailsClicked() {
    if (this.props.onViewDetailsClick)
      this.props.onViewDetailsClick(this.props.announcement);
  }

  render() {
    const { announcement } = this.props;

    return (
      <AnnouncementBaseView announcement={announcement} className="announcement-list-item">
        <div className="view-details-container">
          <a className="view-details-btn" onClick={this.viewDetailsClicked}>Details<ArrowRightIcon size="16" /></a>
        </div>
      </AnnouncementBaseView>
    );
  }
}

export default AnnouncementListItemView;