import React from 'react';
import { observer } from 'mobx-react';
import SmileIcon from 'react-feather/dist/icons/smile';
import TooltipButton from './TooltipButton';
import classNames from 'classnames';
import { Picker as EmojiPicker } from 'emoji-mart';
import OutsideClickHandler from 'react-outside-click-handler';
import { bindSetState } from "../helpers/React";

import 'emoji-mart/css/emoji-mart.css'
import './TextEditor.scss';

class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emojiPickerShown: false
    };

    this.textarea = React.createRef();

    this.showEmojiPicker = bindSetState(this, { emojiPickerShown: true });
    this.hideEmojiPicker = bindSetState(this, { emojiPickerShown: false });

    this.hideEmojiPickerOnNextEventCycle = this.hideEmojiPickerOnNextEventCycle.bind(this);

    this.textChanged = this.textChanged.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.emojiSelected = this.emojiSelected.bind(this);
  }

  textChanged(event) {
    const text = event.target.value;
    this.props.textDraft.text = text;

    if (this.props.changed)
      this.props.changed(text);
  }

  focus() {
    this.textarea.current.focus();
  }

  moveCursorToEnd() {
    this.textarea.current.selectionStart = this.textarea.current.selectionEnd = this.props.textDraft.text.length;
  }

  replaceSelection(content, { focus, len } = {}) {
    const { textDraft } = this.props;
    const selectionStart = this.textarea.current.selectionStart;
    const selectionEnd = this.textarea.current.selectionEnd;

    const oldLen = selectionEnd - selectionStart;

    textDraft.text = textDraft.text.substring(0, selectionStart)
      + content
      + textDraft.text.substring(selectionEnd);

    if (this.props.changed)
      this.props.changed(textDraft.text);

    setTimeout(() => {
      this.textarea.current.selectionStart = this.textarea.current.selectionEnd =
        selectionEnd - oldLen + (len ? len : content.length);

      if (focus)
        this.focus();
    });
  }

  keyDown(event) {
    if (event.key !== 'Enter')
      return;

    event.preventDefault();

    if (!event.ctrlKey && this.props.enterKeyPressed) {
      this.props.enterKeyPressed();
      return;
    }

    this.replaceSelection('\n');
  }

  hideEmojiPickerOnNextEventCycle() {
    setTimeout(this.hideEmojiPicker);
  }

  emojiSelected({ native }) {
    this.hideEmojiPicker();

    if (native)
      this.replaceSelection(native, { focus: true });
  }

  render() {
    const { textDraft, isInvalid } = this.props;
    const { emojiPickerShown } = this.state;

    return (
      <div className="text-editor">
        <textarea
          ref={this.textarea}
          className={classNames('form-control', { 'is-invalid': isInvalid })}
          autoFocus
          value={textDraft.text}
          onChange={this.textChanged}
          onKeyDown={this.keyDown}
        />

        <TooltipButton tooltip={!emojiPickerShown && 'Emoji'} className={classNames('emoji-btn', { 'active': emojiPickerShown })} tooltipPlacement="top">
          <SmileIcon size="24" onClick={emojiPickerShown ? null : this.showEmojiPicker} />
        </TooltipButton>

        {emojiPickerShown && <OutsideClickHandler onOutsideClick={this.hideEmojiPickerOnNextEventCycle}>
          <EmojiPicker
            native
            /* excluding emojis that consist of several emojis - they get inserted as several separate emojis into the textarea */
            emojisToShowFilter={emoji => emoji.unified.indexOf('-') < 0}
            exclude={["recent", "flags"]}
            title=""
            showPreview={false}
            onSelect={this.emojiSelected}
          />
        </OutsideClickHandler>}
      </div>
    );
  }
}

export default observer(TextEditor);