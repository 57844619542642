import React from 'react';
import { observer } from 'mobx-react';
import Spinner from "./Spinner";
import TextBubble from "./TextBubble";
import SendIcon from 'react-feather/dist/icons/send';
import ClockIcon from 'react-feather/dist/icons/clock';
import XIcon from 'react-feather/dist/icons/x';
import CheckmarkIcon from 'react-feather/dist/icons/check';
import AlertIcon from 'react-feather/dist/icons/alert-triangle';
import { defaultTimestampFormat } from "../Constants";
import Announcement from "../data/Announcement";
import classNames from 'classnames';

import './AnnouncementBaseView.scss';

class AnnouncementBaseView extends React.Component {
  render() {
    const { announcement: { text, state, scheduled, isProcessing }, className: propClassName, children } = this.props;
    const { states } = Announcement;

    let className, stateContent, StateIcon;

    switch (state) {
      case states.sending:
        className = 'sending';
        StateIcon = SendIcon;
        stateContent = 'Sending';
        break;

      case states.scheduled:
        className = 'scheduled';
        StateIcon = ClockIcon;
        stateContent = !scheduled ? 'Preparing to be sent' : ('Scheduled for ' + scheduled.format(defaultTimestampFormat));
        break;

      case states.canceled:
        className = 'canceled';
        StateIcon = XIcon;
        stateContent = 'Canceled';
        break;

      case states.success:
        className = 'success';
        StateIcon = CheckmarkIcon;
        stateContent = 'Sent';
        break;

      case states.partialSuccess:
        className = 'partial-success';
        StateIcon = CheckmarkIcon;
        stateContent = 'Sent (with errors)';
        break;

      case states.failure:
        className = 'failure';
        StateIcon = AlertIcon;
        stateContent = 'Failed';
        break;

      default:
        stateContent = 'Unknown';
    }

    const iconSize = 16;

    return (
      <div className={classNames('announcement', propClassName, className)}>
        <TextBubble text={text} isIncoming={false} />

        <div className="details">
          {isProcessing
            ? <Spinner size={iconSize} />
            : <div className="state">{StateIcon && <StateIcon size={iconSize} />}{stateContent}</div>}
          {children}
        </div>
      </div>
    );
  }
}

export default observer(AnnouncementBaseView);