import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import SendIcon from 'react-feather/dist/icons/send';
import TextEditor from "./TextEditor";
import TextTemplatesDropdown from "./TextTemplatesDropdown";
import AttachmentSelector from '../components/AttachmentSelector';
import TooltipButton from './TooltipButton';
import { trimAsString } from '../helpers/String';

import './OutgoingTextEditor.scss';

class OutgoingTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.textEditor = React.createRef();

    this.attachmentSelected = this.attachmentSelected.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.send = this.send.bind(this);
    this.textTemplateSelected = this.textTemplateSelected.bind(this);
  }

  attachmentSelected(file) {
    this.props.textDraft.attachment = file;
  }

  removeAttachment() {
    this.props.textDraft.attachment = null;
  }

  send() {
    let { text, attachment, processingAttachment } = this.props.textDraft;
    text = trimAsString(text);

    if ((!text.length && !attachment) || processingAttachment || !this.props.sendText)
      return;

    this.props.sendText({ text, attachment });
    this.props.textDraft.clear();
  }

  textTemplateSelected(text) {
    this.textEditor.current.replaceSelection(text, { focus: true });
  }

  render() {
    const { textDraft, session } = this.props;

    return (
      <div className="outgoing-text-editor">
        <TextEditor ref={this.textEditor} textDraft={textDraft} enterKeyPressed={this.send} />

        <AttachmentSelector textDraft={textDraft} />

        <TextTemplatesDropdown textDraft={textDraft} session={session} textTemplateSelected={this.textTemplateSelected} />

        <TooltipButton tooltip={!textDraft.processingAttachment && 'Send'} className="send-btn" tooltipPlacement="top">
          <Button color="primary" onClick={this.send} disabled={textDraft.processingAttachment}><SendIcon size="24" /></Button>
        </TooltipButton>
      </div>
    );
  }
}

export default observer(OutgoingTextEditor);