import { decorate, observable } from "mobx";
import findText from "./findText";
import { trimAsString } from "../helpers/String";

class TextSearch {
  constructor({ combinedContact, session }) {
    this.combinedContact = combinedContact;
    this.session = session;

    this.isSearching = false;
    this.currentSearch = null;
    this.foundText = null;
  }

  searchEnded(foundText) {
    this.currentSearch = null;
    this.foundText = foundText;
    this.isSearching = false;
  }

  find(search, next, notFound) {
    if (this.currentSearch)
      this.currentSearch.cancel();

    this.currentSearch = null;
    this.isSearching = false;

    if (!trimAsString(search)) {
      this.foundText = null;
      return;
    }

    this.isSearching = true;

    this.currentSearch = findText({
        search
      , combinedContact: this.combinedContact
      , afterText: this.foundText
      , next
      , session: this.session
    });

    this.currentSearch.promise.then(({ cancelled, text }) => {
      if (cancelled)
        return;

      this.searchEnded(text);

      if (!text && notFound)
        notFound(search);
    });
  }

  cancel() {
    if (this.currentSearch)
      this.currentSearch.cancel();

    this.searchEnded(null);
  }
}

decorate(TextSearch, {
    isSearching: observable
  , foundText: observable
});

export default TextSearch;