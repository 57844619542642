import _ from "lodash/collection";
import { trimAsString } from "../helpers/String";
import { observe } from "mobx";

function processSearch(search) {
  return trimAsString(search).toLowerCase();
}

function textContainsSearch(text, search) {
  return text.text.toLowerCase().indexOf(search) >= 0;
}

function findTextInHistory({ search, combinedContact, afterText, next }) {
  search = processSearch(search);

  const { dialogHistory, newTexts, unreadTexts } = combinedContact;

  let texts = [];

  if (dialogHistory)
    texts = texts.concat(dialogHistory.textsWithoutUnreadTexts(unreadTexts));

  texts = texts.concat(newTexts.texts);

  const afterTextIndex = texts.indexOf(afterText);

  const startIndex = afterTextIndex >= 0
    ? (afterTextIndex + (next ? 1 : -1))
    : (next ? 0 : (texts.length - 1));

  let text = null;

  if (next)
    text = _.find(texts, t => textContainsSearch(t, search), startIndex);
  else {
    for (let i = startIndex; i >= 0; i--) {
      const t = texts[i];

      if (textContainsSearch(t, search)) {
        text = t;
        break;
      }
    }
  }

  return {
      text
    , requiresMoreHistory: !text && !next && dialogHistory && !dialogHistory.allHistoryFetched
  };
}

function findText({ search, combinedContact, afterText, next, session }) {
  let cancelled = false;

  return {
    cancel() {
      cancelled = true;
    }
    , promise: new Promise((resolve) => {
      const check = () => {
        const { text, requiresMoreHistory } = findTextInHistory({ search, combinedContact, afterText, next });

        if (cancelled) {
          resolve({
            cancelled
          });
          return;
        }

        if (text || !requiresMoreHistory) {
          resolve({
            text
          });
          return;
        }

        const { dialogHistory } = combinedContact;
        dialogHistory.fetchTexts(session.auth.token);

        const dispose = observe(dialogHistory, 'isFetching', (change) => {
          dispose();

          check();
        });
      };

      check();
    })
  };
}

export default findText;