import React from 'react';
import InputFiles from 'react-input-files';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Col, FormGroup, Alert, Progress } from 'reactstrap';
import FileIcon from 'react-feather/dist/icons/file-text';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Papa from "papaparse";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { Scrollbars } from "react-custom-scrollbars";
import AnnouncementDetailView from "../components/AnnouncementDetailView";
import { moment } from "../helpers/moment";
import { isDefinedAndNotNull } from "../helpers/Object";
import { stripNonDigits, trimAsString } from "../helpers/String";
import { bindSetState } from "../helpers/React";
import { defaultTimestampFormat, fontFamily, primaryColor } from '../Constants';
import TextDraft from "../core/TextDraft";
import TextEditor from "../components/TextEditor";
import Contact from "../data/Contact";
import InfoModal from "./InfoModal";
import Announcement from "../data/Announcement";
import MiddleCenter from "../components/MiddleCenter";
import Spinner from "../components/Spinner";
import _ from "lodash/collection";

import './AddAnnouncementModal.scss';

class AddAnnouncementModal extends React.Component {
  static immediatelyText = 'Immediately';

  static overlayAnimProps = {
    initial: {
      opacity: 0
    }
    , animate: {
      opacity: 1
    }
    , exit: {
      opacity: 0
    }
    , transition: { duration: 0.3 }
  };

  static spinnerAnimProps = {
    initial: {
      opacity: 1
    }
    , exit: {
      opacity: 0
    }
    , transition: { duration: 0.15 }
  };

  static announcementAnimProps = {
    initial: {
        opacity: 0
      , y: -10
    }
    , animate: {
        opacity: 1
      , y: 0
    }
    , transition: { duration: 0.15 }
  };

  static muiTheme = createMuiTheme({
    typography: {
      fontFamily
    }
    , palette: {
      primary: {
        main: primaryColor
      }
    }
    , overrides: {
      MuiInputBase: {
        input: {
          /*
          Overriding everything from: @material-ui/core/InputBase/InputBase.js (line 119)
          */
          font: null,
          color: null,
          padding: null,
          border: null,
          boxSizing: null,
          background: null,
          height: null,
          margin: null,
          WebkitTapHighlightColor: null,
          display: null,
          minWidth: null,
          width: null,
          animationName: null,
          '&::-webkit-input-placeholder': null,
          '&::-moz-placeholder': null,
          '&:-ms-input-placeholder': null,
          '&::-ms-input-placeholder': null,
          '&:focus': null,
          '&:invalid': null,
          '&::-webkit-search-decoration': null,
          'label[data-shrink=false] + $formControl &': null,
          '&$disabled': null,
          '&:-webkit-autofill': null
        }
      }
    }
    , props: {
      MuiButtonBase: {
        disableRipple: true
      }
      , MuiInput: {
          disableUnderline: true
        , classes: {
          input: 'btn btn-primary'
        }
        , type: 'button'
      }
    }
  });

  constructor(props) {
    super(props);

    const getInitialState = () => {
      return {
          textDraft: new TextDraft()
        , textError: null

        , phoneNumbersText: ''
        , phoneNumbersError: null

        , scheduleDate: null
        , scheduleDateError: null

        , infoModalTitle: ''
        , infoModalBody: null
        , infoModalShown: false

        , adding: false
        , error: null

        , progress: 0

        , announcement: null
      };
    };

    this.state = getInitialState();

    this.minScheduleDate = moment();

    this.textEditor = React.createRef();

    this.shown = this.shown.bind(this);
    this.hidden = bindSetState(this, () => getInitialState());
    this.hideClicked = this.hideClicked.bind(this);
    this.textChanged = this.textChanged.bind(this);
    this.phoneNumbersTextChanged = this.phoneNumbersTextChanged.bind(this);
    this.addClicked = this.addClicked.bind(this);
    this.fileSelected = this.fileSelected.bind(this);
    this.scheduleDateChanged = this.scheduleDateChanged.bind(this);
    this.hideInfoModal = bindSetState(this, { infoModalShown: false });
  }

  hideClicked() {
    if (!this.state.adding && this.props.hide)
      this.props.hide();
  }

  shown() {
    this.textEditor.current.moveCursorToEnd();
    this.textEditor.current.focus();
  }

  showError(error) {
    this.setState({
        infoModalTitle: 'Announcement'
      , infoModalBody: error
      , infoModalShown: true
    });
  }

  static getTextError(text) {
    text = trimAsString(text);

    const textError = text.length
      ? null
      : 'Please enter the announcement';

    return textError;
  }

  textChanged(text) {
    this.setState({
      textError: AddAnnouncementModal.getTextError(text)
    });
  }

  fileSelected(files) {
    if (!files || !files.length)
      return;

    const file = files[0];

    Papa.parse(file, {
        skipEmptyLines: 'greedy'
      , complete: ({ data: rows, errors }) => {
        /*
        when there is only one column and it's values do not end with a delimiter
        or when it's an empty file and there are no columns
        PapaParse always reports an 'UndetectableDelimiter' error which we ignore
        */
        errors = _.filter(errors, (error) => error.code !== 'UndetectableDelimiter');

        if (!rows.length && !errors.length) {
          this.showError('The selected file is empty.');
          return;
        }

        if (errors.length) {
          this.showError('The selected file has invalid format.');
          return;
        }

        let phoneNumbersText = '';

        rows.forEach(row => {
          if (!row.length)
            return;

          const phoneNumber = trimAsString(row[0]);

          if (!phoneNumber)
            return;

          if (phoneNumbersText)
            phoneNumbersText += ', ';

          phoneNumbersText += phoneNumber;
        });

        if (!phoneNumbersText) {
          this.showError('The first column of the selected file is empty.');
          return;
        }

        this.setPhoneNumbersText(phoneNumbersText);
      }
    });
  }

  static extractPhoneNumbers(text) {
    const phoneNumbers = text.split(/[\r?\n,]/);
    const result = [];

    phoneNumbers.forEach(phoneNumber => {
      phoneNumber = trimAsString(phoneNumber);

      if (!phoneNumber)
        return;

      let validationResult = Contact.validatePhoneNumber(phoneNumber);
      const processedPhoneNumber = stripNonDigits(phoneNumber);

      if (validationResult.isValid && _.find(result, p => p.processedPhoneNumber === processedPhoneNumber))
        validationResult = {
            isValid: false
          , isDuplicate: true
        };

      result.push({
          phoneNumber
        , validationResult
        , processedPhoneNumber
      });
    });

    return result;
  }

  static getPhoneNumbersError(phoneNumbers) {
    let phoneNumbersError = null;

    const { maxPhoneNumbers } = Announcement;

    if (!phoneNumbers.length)
      phoneNumbersError = 'Please specify the phone numbers';
    else if (phoneNumbers.length > maxPhoneNumbers) {
      phoneNumbersError = `Only up to ${maxPhoneNumbers} phone numbers are supported - you have specified ${phoneNumbers.length} numbers`;
    }
    else {
      let invalidCount = 0;
      let firstInvalid = null;

      phoneNumbers.forEach(phoneNumber => {
        if (phoneNumber.validationResult.isValid)
          return;

        if (!firstInvalid)
          firstInvalid = phoneNumber;

        invalidCount++;
      });

      if (invalidCount) {
        const r = firstInvalid.validationResult;

        phoneNumbersError = '"' + firstInvalid.phoneNumber + '" '
          + (r.noDigits
            ? 'does not contain digits'
            : (r.doesNotStartWith1
              ? 'does not start with "1"'
              : (r.invalidLength
                ? `has ${r.invalidLength} of required ${r.requiredLength} digits`
                : (r.isDuplicate
                  ? 'occurs more than once in this list'
                  : 'has invalid format'))));

        if (invalidCount > 1) {
          const otherErrorsCount = invalidCount - 1;

          phoneNumbersError += `, ${otherErrorsCount} other number${otherErrorsCount === 1 ? ' has an error' : 's have errors'}`;
        }
      }
    }

    return phoneNumbersError;
  }

  static processPhoneNumbersText(text) {
    const phoneNumbers = AddAnnouncementModal.extractPhoneNumbers(text);
    const phoneNumbersError = AddAnnouncementModal.getPhoneNumbersError(phoneNumbers);

    return {
        phoneNumbers
      , phoneNumbersError
    };
  }

  setPhoneNumbersText(phoneNumbersText) {
    const { phoneNumbersError } = AddAnnouncementModal.processPhoneNumbersText(phoneNumbersText);

    this.setState({
        phoneNumbersText
      , phoneNumbersError
    });
  }

  phoneNumbersTextChanged(event) {
    this.setPhoneNumbersText(event.target.value);
  }

  static getScheduleDateError(date) {
    let scheduleDateError = null;

    if (date) {
      if (!date.isValid())
        scheduleDateError = 'Invalid date';
      else {
        const now = moment();

        if (date.isBefore(now))
          scheduleDateError = 'The selected time is in the past';
        else if (date.isSameOrBefore(now.add(5, 'm')))
          scheduleDateError = 'The selected time is too close to the present moment';
      }
    }

    return scheduleDateError;
  }

  scheduleDateChanged(date) {
    this.setState({
        scheduleDate: date
      , scheduleDateError: AddAnnouncementModal.getScheduleDateError(date)
    });
  }

  addClicked() {
    const { adding, textDraft: { text }, phoneNumbersText, scheduleDate } = this.state;

    if (adding)
      return;

    const textError = AddAnnouncementModal.getTextError(text);
    const { phoneNumbers, phoneNumbersError } = AddAnnouncementModal.processPhoneNumbersText(phoneNumbersText);
    const scheduleDateError = AddAnnouncementModal.getScheduleDateError(scheduleDate);

    this.setState({
        textError
      , phoneNumbersError
      , scheduleDateError
    });

    if (textError || phoneNumbersError || scheduleDateError)
      return;

    this.setState({
        adding: true
      , progress: scheduleDate ? null : 0
    });

    const { session } = this.props;

    Announcement.add(session.auth.token, {
        text
      , phoneNumbers: phoneNumbers.map(p => p.processedPhoneNumber)
      , scheduled: scheduleDate
    }).then((announcement) => {
      session.announcementList.addToTheTop(announcement);

      if (scheduleDate) {
        this.setState({
            adding: false
          , announcement
        });

        return;
      }

      const { states } = Announcement;

      const progressIncrementDelay = 250;
      const progressIncrement = 100 / (Announcement.estimateAddDuration(phoneNumbers.length) / progressIncrementDelay);
      let progressIntervalId;

      const refresh = () => {
        announcement.refresh(session.auth.token)
          .then(() => {
            switch (announcement.state) {
              case states.canceled:
              case states.success:
              case states.partialSuccess:
              case states.failure:
                if (progressIntervalId)
                  clearInterval(progressIntervalId);

                this.setState({
                  progress: 100
                }, () => {
                  setTimeout(() => {
                    this.setState({
                        adding: false
                      , announcement
                    });
                  }, 500);
                });
                return;

              case states.sending:
                if (!progressIntervalId)
                  progressIntervalId = setInterval(() => {
                    this.setState(({ progress }) => {
                      return {
                        progress: progress + progressIncrement
                      };
                    });
                  }, progressIncrementDelay);

                //intentionally no break
              default:
                setTimeout(refresh, 1000);
            }
          });
      };

      setTimeout(refresh, 1000);
    }).catch(() => {
      this.setState({
          adding: true
        , error: 'Failed to create the announcement'
      });
    });
  }

  static formatDate(date, invalidLabel) {
    return !date
      ? AddAnnouncementModal.immediatelyText
      : (date.isValid() ? date.format(defaultTimestampFormat) : (invalidLabel || 'Invalid date'));
  }

  render() {
    const { session } = this.props;

    const { textDraft, textError, phoneNumbersText, phoneNumbersError, fileName, scheduleDate, scheduleDateError
      , infoModalShown, infoModalTitle, infoModalBody, error, adding, progress, announcement } = this.state;

    const { muiTheme, formatDate, immediatelyText, overlayAnimProps, spinnerAnimProps, announcementAnimProps } = AddAnnouncementModal;

    return (
      <Modal isOpen={this.props.shown} toggle={this.hideClicked} onOpened={this.shown} onClosed={this.hidden} size="lg" modalClassName="add-announcement-modal">
        <ModalHeader toggle={this.hideClicked}>New Announcement</ModalHeader>
        <ModalBody>
          {error && (<Alert color="danger">{error}</Alert>)}

          <FormGroup row>
            <Label sm={3}>Announcement</Label>
            <Col sm={9}>
              <TextEditor
                ref={this.textEditor}
                textDraft={textDraft}
                isInvalid={textError}
                changed={this.textChanged}
              />
              {textError && (<div className="input-error">{textError}</div>)}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Phone Numbers</Label>
            <Col sm={9}>
              <p>
                <InputFiles onChange={this.fileSelected} accept=".csv">
                  <Button type="button" className="select-file-btn" color="primary">
                    <FileIcon size="18" />{fileName || 'Import from CSV'}
                  </Button>
                </InputFiles>
              </p>
              <textarea
                className={classNames('form-control', 'phone-numbers', { 'is-invalid': phoneNumbersError })}
                value={phoneNumbersText}
                onChange={this.phoneNumbersTextChanged}
              />
              {phoneNumbersError && (<div className="input-error">{phoneNumbersError}</div>)}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Send</Label>
            <Col sm={9}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={muiTheme}>
                  <DateTimePicker
                    value={scheduleDate}
                    minDate={this.minScheduleDate}
                    onChange={this.scheduleDateChanged}
                    labelFunc={formatDate}
                    emptyLabel={immediatelyText}
                    clearLabel={immediatelyText}
                    clearable
                    disablePast
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
              {scheduleDateError && (<div className="input-error">{scheduleDateError}</div>)}
            </Col>
          </FormGroup>

          <InfoModal shown={infoModalShown} title={infoModalTitle} hide={this.hideInfoModal}>
            {infoModalBody}
          </InfoModal>

          <AnimatePresence exitBeforeEnter>
            {(adding || announcement) && <motion.div key="overlay" className="overlay" {...overlayAnimProps}>
              {adding && <motion.div key="spinner" className="overlay-inner" {...spinnerAnimProps}>
                <MiddleCenter>
                  {isDefinedAndNotNull(progress)
                    ? <Progress value={progress} />
                    : <Spinner />}
                </MiddleCenter>
              </motion.div>}

              {announcement && <motion.div key="announcement" className="overlay-inner" {...announcementAnimProps}>
                <Scrollbars>
                  <AnnouncementDetailView announcement={announcement} session={session} />
                </Scrollbars>
              </motion.div>}
            </motion.div>}
          </AnimatePresence>

        </ModalBody>
        <ModalFooter>
          {!announcement && [<Button color="primary" onClick={this.addClicked} disabled={adding || textError || phoneNumbersError || scheduleDateError}>{scheduleDate ? 'Create' : 'Send'}</Button>, ' ']}
          <Button color="secondary" onClick={this.hideClicked} disabled={adding}>{announcement ? 'OK' : 'Cancel'}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddAnnouncementModal;