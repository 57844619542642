import { executeRequest } from "../core/Request";
import { combineUrlParts } from "../helpers/Url";
import { decorate, observable } from "mobx";

class User {
  static requestBase = 'users';

  constructor({ id, email, name, notificationsAvailable, notificationsEnabled } = {}) {
    this.id = id;
    this.email = email;
    this.name = name;
    this.notificationsAvailable = notificationsAvailable;
    this.notificationsEnabled = notificationsEnabled;
  }

  static fromRawData({ id, email, display_name, notifications_available, notifications_enabled }) {
    return new User({
        id: +id
      , email
      , name: display_name
      , notificationsAvailable: notifications_available
      , notificationsEnabled: notifications_enabled
    });
  }

  updateNotificationsEnabled(token, notificationsEnabled) {
    const data = {
      notifications_enabled: notificationsEnabled
    };

    return executeRequest({
        method: 'patch'
      , request: combineUrlParts(User.requestBase, this.id)
      , token
      , data
    }).then(() => {
      this.notificationsEnabled = notificationsEnabled;
    });
  }
}

decorate(User, {
  notificationsEnabled: observable
});

export default User;