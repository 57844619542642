import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class InfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.hideClicked = this.hideClicked.bind(this);
  }

  hideClicked() {
    if (this.props.hide)
      this.props.hide();
  }

  render() {
    const { shown, modalClassName, title, children} = this.props;

    return (
      <Modal isOpen={shown} toggle={this.hideClicked} modalClassName={modalClassName}>
        <ModalHeader toggle={this.hideClicked}>{title}</ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.hideClicked}>OK</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default InfoModal;