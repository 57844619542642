import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import MailIcon from 'react-feather/dist/icons/mail';
import XIcon from 'react-feather/dist/icons/x';
import VolumeIcon from 'react-feather/dist/icons/volume';
import BellIcon from 'react-feather/dist/icons/bell';
import BellOffIcon from 'react-feather/dist/icons/bell-off';
import Spinner from "./Spinner";
import TooltipButton from './TooltipButton';
import AddAnnouncementModal from "../modals/AddAnnouncementModal";
import AnnouncementsModal from "../modals/AnnouncementsModal";
import InfoModal from "../modals/InfoModal";
import { bindSetState } from "../helpers/React";
import { appTitle } from "../Constants";
import classNames from 'classnames';

import './HeaderView.scss';

class HeaderView extends Component {
  constructor(props) {
    super(props);

    this.state = {
        addAnnouncementModalShown: false
      , announcementsModalShown: false
      , notificationsModalShown: false
      , processingNotificationsEnabled: false
    };

    this.showAnnouncementsModal = bindSetState(this, { announcementsModalShown: true });
    this.hideAnnouncementsModal = bindSetState(this, { announcementsModalShown: false });
    this.showAddAnnouncementModal = bindSetState(this, { addAnnouncementModalShown: true });
    this.hideAddAnnouncementModal = bindSetState(this, { addAnnouncementModalShown: false });
    this.showNotificationsModal = bindSetState(this, { notificationsModalShown: true });
    this.hideNotificationsModal = bindSetState(this, { notificationsModalShown: false });

    this.logoClicked = this.logoClicked.bind(this);
    this.logOut = this.logOut.bind(this);
    this.toggleNotificationsEnabled = this.toggleNotificationsEnabled.bind(this);
  }

  logoClicked() {
    if (this.props.logoClicked)
      this.props.logoClicked();
  }

  logOut() {
    if (this.props.logOutClicked)
      this.props.logOutClicked();
  }

  toggleNotificationsEnabled() {
    if (this.state.processingNotificationsEnabled)
      return;

    this.setState({
      processingNotificationsEnabled: true
    });

    const complete = () => {
      this.setState({
        processingNotificationsEnabled: false
      });
    };

    const { session: { auth } } = this.props;
    const newNotificationsEnabled = !auth.user.notificationsEnabled;

    auth.user.updateNotificationsEnabled(auth.token, newNotificationsEnabled)
      .then(() => {
        complete();

        if (newNotificationsEnabled)
          this.showNotificationsModal();
      })
      .catch(complete);
  }

  render() {
    const { session } = this.props;
    const { addAnnouncementModalShown, announcementsModalShown, notificationsModalShown, processingNotificationsEnabled } = this.state;

    const { email, notificationsAvailable, notificationsEnabled } = session.auth.user;

    return (
      <div className="header">
        <div className="logo-container">
          <div className="logo" onClick={this.logoClicked}>
            <MailIcon size="21" /> {appTitle}
          </div>
        </div>

        <UncontrolledButtonDropdown className="announcements-dropdown">
          <DropdownToggle caret color="primary">
            <VolumeIcon size="21" /><span>Announcements</span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.showAddAnnouncementModal}>New Announcement</DropdownItem>
            <DropdownItem onClick={this.showAnnouncementsModal}>All Announcements</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>

        <TooltipButton className="log-out-btn" onClick={this.logOut} tooltip="Logout">
          <XIcon size="22" />
        </TooltipButton>

        <div className="user">
          <div className="name">{session.auth.user.name}</div>
          <div className="phone-number">
            {session.phoneNumber
              ? session.phoneNumber.phoneNumber
              : 'No phone number'}
          </div>
        </div>

        {notificationsAvailable && <TooltipButton
          className={classNames('notifications-btn', { 'processing': processingNotificationsEnabled })}
          onClick={this.toggleNotificationsEnabled}
          tooltip={!processingNotificationsEnabled && ('E-mail notifications ' + (notificationsEnabled ? 'enabled' : 'disabled'))}>
          {processingNotificationsEnabled
            ? <Spinner size="18" />
            : (notificationsEnabled ? <BellIcon size="18" /> : <BellOffIcon size="18" />)}
        </TooltipButton>}

        <AddAnnouncementModal
          session={session}
          shown={addAnnouncementModalShown}
          hide={this.hideAddAnnouncementModal}
        />

        <AnnouncementsModal
          session={session}
          shown={announcementsModalShown}
          hide={this.hideAnnouncementsModal}
        />

        <InfoModal
          shown={notificationsModalShown}
          title="E-mail Notifications"
          hide={this.hideNotificationsModal}
          modalClassName="notifications-modal">
          <p>E-mail notifications are now enabled.</p>
          <p>Notifications will be sent to <span>{email}</span></p>
        </InfoModal>
      </div>
    );
  }
}

export default observer(HeaderView);