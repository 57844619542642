import { trimAsString } from "./helpers/String";

/*
requestUrl should reference the folder where the backend API is deployed on the server
*/
const requestUrl = '../backend/api/';

const appTitle = trimAsString(process.env.REACT_APP_TITLE);
const logoUrl = trimAsString(process.env.REACT_APP_LOGO_URL);
const isDebug = !!process.env.REACT_APP_DEBUG;

const maxAttachmentSize = 1000000;
const maxImageFileSize = 200 * 1000000;

const defaultTimestampFormat = 'D MMM YYYY, h:mm A';

const fontFamily = "'Nunito Sans',sans-serif";
const primaryColor = '#277fdd';

export {
    requestUrl
  , maxAttachmentSize
  , maxImageFileSize
  , appTitle
  , logoUrl
  , isDebug
  , defaultTimestampFormat
  , fontFamily
  , primaryColor
};