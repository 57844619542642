import React from 'react';
import SearchIcon from 'react-feather/dist/icons/search';
import XIcon from 'react-feather/dist/icons/x';
import Spinner from "./Spinner";
import { motion, AnimatePresence } from "framer-motion";

import './SearchInput.scss';

class SearchInput extends React.Component {
  static clearBtnAnimProps = {
    initial: {
      opacity: 0
    }
    , animate: {
      opacity: 1
    }
    , exit: {
      opacity: 0
    }
    , transition: { duration: 0.15 }
  };

  constructor(props) {
    super(props);

    this.state = {
      search: props.initialSearch || ''
    };

    this.input = React.createRef();

    this.changed = this.changed.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.clear = this.clear.bind(this);
  }

  searchChanged(search) {
    this.setState({
      search
    });

    if (this.props.changed)
      this.props.changed(search);
  }

  changed(event) {
    this.searchChanged(event.target.value);
  }

  keyDown(event) {
    if (event.key !== 'Enter')
      return;

    event.preventDefault();

    if (this.props.enterKeyPressed)
      this.props.enterKeyPressed();
  }

  clear() {
    if (this.input.current)
      this.input.current.focus();

    this.searchChanged('');

    if (this.props.cleared)
      this.props.cleared();
  }

  render() {
    const { placeholder, processing } = this.props;
    const { search } = this.state;

    const inputProps = {};

    if (processing)
      inputProps.readOnly = true;

    return (
      <div className="search">
        <input ref={this.input} type="text" placeholder={placeholder} value={search} onChange={this.changed} {...inputProps} />
        {processing ? <Spinner size="16" /> : <SearchIcon className="search-icon" size="16" />}
        <AnimatePresence>
          {search && <motion.div key="clear" className="clear-container" {...SearchInput.clearBtnAnimProps}>
            <XIcon className="clear-icon" size="16" onClick={this.clear} />
          </motion.div>}
        </AnimatePresence>
      </div>
    );
  }
}

export default SearchInput;