import React from 'react';
import { observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';
import SadIcon from 'react-feather/dist/icons/frown';
import MiddleCenter from './MiddleCenter';
import Spinner from './Spinner';
import { bindSetState } from "../helpers/React";
import { isUndefinedOrNull } from '../helpers/Object';

import './AttachmentView.scss';

class AttachmentView extends React.Component {
  static maxSideLength = 350;
  static defaultSideLength = 200;

  constructor(props) {
    super(props);

    this.state = {
        animated: !this.props.attachment.downloaded
      , loaded: false
      , lightboxShown: false
    };

    this.showLightbox = bindSetState(this, { lightboxShown: true });
    this.hideLightbox = bindSetState(this, { lightboxShown: false });
    this.loaded = bindSetState(this, { loaded: true });
  }

  ensureDownloaded() {
    this.props.attachment.download(this.props.auth.token);
  }

  componentDidMount() {
    this.ensureDownloaded();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.attachment === this.props.attachment)
      return;

    this.setState({
        animated: !this.props.attachment.downloaded
      , loaded: false
    });

    this.ensureDownloaded();
  }

  render() {
    const attachment = this.props.attachment;

    let { width, height } = attachment;

    if (isUndefinedOrNull(width) || isUndefinedOrNull(height))
      width = height = AttachmentView.defaultSideLength;
    else {
      let ratio;

      if (width > AttachmentView.maxSideLength) {
        ratio = AttachmentView.maxSideLength / width;
        width = AttachmentView.maxSideLength;
        height *= ratio;
      }

      if (height > AttachmentView.maxSideLength) {
        ratio = AttachmentView.maxSideLength / height;
        height = AttachmentView.maxSideLength;
        width *= ratio;
      }
    }

    let attachmentClass = 'attachment';
    let attachmentContents = null;
    let renderPlaceholder = true;

    if (attachment.downloaded) {
      //todo refactor
      if (attachment.downloadError)
        return (
          <div className={attachmentClass} style={{ height }}>
            <div className="placeholder" style={{ width, height }}>
              <MiddleCenter className="error">
                <SadIcon width="30%" height="30%" />
                <div>Failed to load the attachment</div>
              </MiddleCenter>
            </div>)
          </div>
        );

      const imgProps = {
          src: attachment.objectURL
        , onClick: this.showLightbox
      };

      if (!this.state.animated) {
        attachmentClass += ' shown';
        renderPlaceholder = false;
      }
      else {
        imgProps.onLoad = this.loaded;

        if (this.state.loaded)
          attachmentClass += ' show-animated';
      }

      attachmentContents = (<img {...imgProps} />);
    }

    return (
      <div className={attachmentClass} style={{ height }}>
        {renderPlaceholder && (<div className="placeholder" style={{ width, height }}>
          <MiddleCenter>
            <Spinner />
          </MiddleCenter>
        </div>)}
        {attachmentContents}
        {this.state.lightboxShown && (<Lightbox
          mainSrc={attachment.objectURL}
          onCloseRequest={this.hideLightbox}
        />)}
      </div>
    );
  }
}

export default observer(AttachmentView);