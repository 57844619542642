import React from 'react';
import DialogHeader from './DialogHeader';
import DialogTextsView from './DialogTextsView';
import MiddleCenter from './MiddleCenter';
import OutgoingTextEditor from './OutgoingTextEditor';
import TextSearch from "../core/TextSearch";
import InfoModal from "../modals/InfoModal";
import { trimAsString } from "../helpers/String";
import { bindSetState } from "../helpers/React";

import './DialogView.scss';

class DialogView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        textSearch: this.createTextSearch()
      , searchNotFoundText: ''
      , searchNotFoundModalShown: false
    };

    this.dialogTextsView = React.createRef();

    this.sendText = this.sendText.bind(this);

    this.showSearchNotFoundModal = this.showSearchNotFoundModal.bind(this);
    this.hideSearchNotFoundModal = bindSetState(this, { searchNotFoundModalShown: false });
  }

  createTextSearch() {
    const { combinedContact, session } = this.props;

    return new TextSearch({ combinedContact, session });
  }

  showSearchNotFoundModal(search) {
    this.setState({
        searchNotFoundText: `Cannot find "${trimAsString(search)}".`
      , searchNotFoundModalShown: true
    });
  }

  sendText({ text, attachment }) {
    this.props.combinedContact.sendText({ session: this.props.session, text, attachment });

    setTimeout(() => {
      this.dialogTextsView.current.scrollToBottom();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.combinedContact === this.props.combinedContact)
      return;

    this.state.textSearch.cancel();

    this.setState({
      textSearch: this.createTextSearch()
    });
  }

  static renderInfo(alert, description) {
    return (
      <MiddleCenter className="info">
        <span>{alert}</span> {description}
      </MiddleCenter>
    );
  }

  render() {
    const { textSearch, searchNotFoundText, searchNotFoundModalShown } = this.state;
    const { session, combinedContact } = this.props;
    const { phoneNumber } = session;

    return (
      <>
        <div className="dialog-header-wrapper">
          <div className="inner">
            <DialogHeader session={session} combinedContact={combinedContact} textSearch={textSearch} textNotFound={this.showSearchNotFoundModal} />
          </div>
        </div>
        <div className="dialog-texts-wrapper">
          <DialogTextsView ref={this.dialogTextsView} session={session} combinedContact={combinedContact} textSearch={textSearch} />

          <InfoModal
            shown={searchNotFoundModalShown}
            title="Search"
            hide={this.hideSearchNotFoundModal}>
            {searchNotFoundText}
          </InfoModal>
        </div>
        <div className="dialog-outgoing-text-wrapper">
          {!phoneNumber
            ? DialogView.renderInfo('No phone number', 'Your account does not have a phone number set up - please contact your administrator.')
            : (combinedContact.dialog && (combinedContact.dialog.userPhoneNumber !== phoneNumber.phoneNumber)
              ? DialogView.renderInfo('Archived chat', `The phone number you used - ${combinedContact.dialog.userPhoneNumber} - is no longer your primary phone number.`)
              : (<OutgoingTextEditor session={session} sendText={this.sendText} textDraft={combinedContact.outgoingTextDraft} />))}
        </div>
      </>
    );
  }
}

export default DialogView;