import DataObject from './DataObject';
import Contact from './Contact';
import { combineUrlParts } from '../helpers/Url';
import { executeRequest } from "../core/Request";
import { decorate, observable } from "mobx";

class Dialog extends DataObject {
  static requestBase = 'dialogs';

  constructor({ id, userPhoneNumber, contactPhoneNumber, contact, archived } = {}) {
    super();

    this.id = id;
    this.userPhoneNumber = userPhoneNumber;
    this.contactPhoneNumber = contactPhoneNumber;
    this.contact = contact;
    this.archived = archived;
  }

  static fromRawData({ id, internal_number, external_number, contact, archived }) {
    return new Dialog({
        id: +id
      , userPhoneNumber: internal_number
      , contactPhoneNumber: external_number
      , contact: contact ? Contact.fromRawData(contact) : null
      , archived: !!+archived
    });
  }

  getTextsRequestUrl() {
    return combineUrlParts(Dialog.requestBase, this.id, 'messages');
  }

  updateArchived(token, archived) {
    const data = {
      archived
    };

    return executeRequest({
        method: 'patch'
      , request: combineUrlParts(Contact.requestBase, this.id)
      , token
      , data
    }).then(() => {
      this.archived = archived;
    });
  }
}

decorate(Dialog, {
  archived: observable
});

export default Dialog;