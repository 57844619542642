import DataObject from "./DataObject";
import { executeRequest } from '../core/Request.js';
import { combineUrlParts } from '../helpers/Url';
import { observable, decorate } from 'mobx';
import { stripNonDigits, trimAsString } from "../helpers/String";

class Contact extends DataObject {
  static requestBase = 'contacts';

  constructor({ id, name, phoneNumber, archived, autoReply } = {}) {
    super();

    this.id = id;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.archived = archived;
    this.autoReply = autoReply;
  }

  static fromRawData({ id, number, display_name, archived, auto_reply }) {
    return new Contact({
        id: +id
      , name: display_name
      , phoneNumber: number
      , archived: !!+archived
      , autoReply: auto_reply
    });
  }

  static add(token, { name, phoneNumber, archived, autoReply }) {
    const data = {
      number: phoneNumber
    };

    if (name)
      data.display_name = name;

    if (archived)
      data.archived = archived;

    if (autoReply)
      data.auto_reply = autoReply;

    return executeRequest({
        method: 'post'
      , request: this.requestBase
      , token
      , data
      , responseDataClass: this
    });
  }

  updateValue(token, value, name, rawName) {
    if (!rawName)
      rawName = name;

    const data = {};
    data[rawName] = value;

    return executeRequest({
        method: 'patch'
      , request: combineUrlParts(Contact.requestBase, this.id)
      , token
      , data
    }).then(() => {
      this[name] = value;
    });
  }

  updateName(token, newName) {
    return this.updateValue(token, newName, 'name', 'display_name');
  }

  updateArchived(token, archived) {
    return this.updateValue(token, archived, 'archived');
  }

  updateAutoReply(token, autoReply) {
    return this.updateValue(token, autoReply, 'autoReply', 'auto_reply');
  }

  remove(token) {
    return executeRequest({
      method: 'delete'
      , request: combineUrlParts(Contact.requestBase, this.id)
      , token
    });
  }

  static addContacts(token, contacts) {
    const data = contacts.map(({ name, phoneNumber }) => {
      const contactData = {
        number: phoneNumber
      };

      if (name)
        contactData.display_name = name;

      return contactData;
    });

    return executeRequest({
        method: 'post'
      , request: combineUrlParts(this.requestBase, 'bulk')
      , token
      , data
      , responseIsArray: true
      , responseDataClass: this
    });
  }

  static validateName(name, isRequired) {
    const result = {
      isValid: false
    };

    name = trimAsString(name);

    const maxLength = 64;
    const len = name.length;

    if (len > maxLength) {
      result.invalidLength = len;
      result.maxLength = maxLength;
    }
    else if (isRequired && !name)
      result.isEmpty = true;
    else
      result.isValid = true;

    return result;
  }

  static validatePhoneNumber(phoneNumber, combinedContactList) {
    phoneNumber = trimAsString(phoneNumber);

    const result = {
      isValid: false
    };

    if (!phoneNumber)
      result.isEmpty = true;
    else {
      phoneNumber = stripNonDigits(phoneNumber);

      if (!phoneNumber)
        result.noDigits = true;
      else {
        const requiredLength = 11;
        const len = phoneNumber.length;

        if (len !== requiredLength) {
          result.invalidLength = len;
          result.requiredLength = requiredLength;
        }
        else if (phoneNumber[0] !== '1')
          result.doesNotStartWith1 = true;
        else if (combinedContactList && combinedContactList.findByPhoneNumber(phoneNumber))
          result.presentInContactList = true;
        else
          result.isValid = true;
      }
    }

    return result;
  }
}

decorate(Contact, {
    name: observable
  , archived: observable
  , autoReply: observable
});

export default Contact;