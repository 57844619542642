import React from 'react';
import { observer } from 'mobx-react';
import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import TextView from './TextView';
import Spinner from './Spinner';

import './DialogTextsView.scss';

class DialogTextsView extends React.Component {
  static markAsReadScrollTopMargin = 15;
  static fetchMoreHistoryScrollTopMargin = 300;

  static textAnimProps = {
    initial: {
      y: -10
    }
    , animate: {
      y: 0
    }
    , transition: { duration: 0.2 }
  };

  constructor(props) {
    super(props);

    this.state = {
      initialNewTextCount: this.props.combinedContact.newTexts.texts.length
    };

    this.scrollbars = React.createRef();
    this.highlightedText = React.createRef();

    this.scrollStopped = this.scrollStopped.bind(this);
    this.windowFocus = this.windowFocus.bind(this);
  }

  newDialogShown() {
    const { dialogHistory, unreadTexts } = this.props.combinedContact;

    if (dialogHistory && !dialogHistory.hasFetched)
      dialogHistory.fetchTexts(this.props.session.auth.token, unreadTexts.earliestUnreadTextId);

    this.scrollbars.current.scrollToBottom();
    this.checkIfTextsWereRead();

    this.setState({
      initialNewTextCount: this.props.combinedContact.newTexts.texts.length
    });
  }

  windowFocus() {
    this.checkIfTextsWereRead();
  }

  componentDidMount() {
    this.newDialogShown();

    window.addEventListener('focus', this.windowFocus, false);
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.windowFocus, false);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { scrollTop, scrollHeight } = this.scrollbars.current.getValues();

    return {
        scrollBottom: scrollHeight - scrollTop
      , highlightedText: this.highlightedText.current
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.combinedContact !== this.props.combinedContact) {
      this.newDialogShown();
      return;
    }

    if (!snapshot)
      return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollbars.current.getValues();

    let newScrollTop;

    const highlightedText = this.highlightedText.current;

    if (!highlightedText || (snapshot.highlightedText === highlightedText))
      newScrollTop = scrollHeight - snapshot.scrollBottom;
    else {
      newScrollTop = highlightedText.getOffsetTop();
      const textHeight = highlightedText.getHeight();

      if (clientHeight > textHeight) {
        newScrollTop = newScrollTop - clientHeight / 2 + textHeight / 2;
      }

      if (newScrollTop < 0)
        newScrollTop = 0;
    }

    if (newScrollTop !== scrollTop)
      this.scrollbars.current.scrollTop(newScrollTop);
    else
      this.checkIfTextsWereRead();
  }

  fetchMoreHistory() {
    const { dialogHistory } = this.props.combinedContact;

    if (dialogHistory)
      dialogHistory.fetchTexts(this.props.session.auth.token);
  }

  scrollToBottom() {
    this.scrollbars.current.scrollToBottom();
  }

  checkIfTextsWereRead() {
    if (!document.hasFocus())
      return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollbars.current.getValues();

    if ((scrollHeight <= clientHeight)
      || ((scrollTop + clientHeight + DialogTextsView.markAsReadScrollTopMargin) >= scrollHeight))
      this.props.combinedContact.textsWereRead(this.props.session.auth.token);
  }

  scrollStopped() {
    const { scrollTop } = this.scrollbars.current.getValues();

    if (scrollTop <= DialogTextsView.fetchMoreHistoryScrollTopMargin)
      this.fetchMoreHistory();

    this.checkIfTextsWereRead();
  }

  render() {
    const { initialNewTextCount } = this.state;
    const { combinedContact: { dialogHistory, newTexts, unreadTexts }, session, textSearch } = this.props;

    let historyTexts, isFetchingHistory;

    if (dialogHistory) {
      historyTexts = dialogHistory.textsWithoutUnreadTexts(unreadTexts);
      isFetchingHistory = dialogHistory.isFetching;
    }

    const alreadySeenNewTexts = newTexts.texts.slice(0, initialNewTextCount);
    const reallyNewTexts = newTexts.texts.slice(initialNewTextCount);

    const highlightedText = textSearch.foundText;

    const renderText = text => {
      const props = {};

      if (text === highlightedText) {
        props.isHighlighted = true;
        props.ref = this.highlightedText;
      }

      return <TextView text={text} auth={session.auth} {...props} />
    };

    return (
      <div className="dialog-texts">
        <Scrollbars ref={this.scrollbars} onScrollStop={this.scrollStopped}>
          <div className="dialog-texts-container">
            {isFetchingHistory && (<div className="fetching-history">
                <div className="inner">
                  <Spinner />
                </div>
              </div>)}
            {historyTexts && historyTexts.map(renderText)}
            {alreadySeenNewTexts.map(renderText)}
            {reallyNewTexts.map(text =>
              (<motion.div key={text.generatedId} {...DialogTextsView.textAnimProps}>
                {renderText(text)}
              </motion.div>))}
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default observer(DialogTextsView);