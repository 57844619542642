import { observable, decorate } from "mobx";
import TextTemplate from "../data/TextTemplate";

class TextTemplateList {
  constructor(textTemplates) {
    this.textTemplates = textTemplates.slice(0);
  }

  add(textTemplate) {
    this.textTemplates.push(textTemplate);
  }

  find(text) {
    return this.textTemplates.find(textTemplate => textTemplate.text === text);
  }

  remove(textTemplate) {
    const idx = this.textTemplates.indexOf(textTemplate);

    if (idx >= 0)
      this.textTemplates.splice(idx, 1);
  }

  static fetch(token) {
    return TextTemplate.fetchAll(token).then(textTemplates => new TextTemplateList(textTemplates));
  }
}

decorate(TextTemplateList, {
  textTemplates: observable
});

export default TextTemplateList;