import React from 'react';
import { observer } from 'mobx-react';
import EditIcon from 'react-feather/dist/icons/edit';
import SearchInput from "./SearchInput";
import TooltipButton from './TooltipButton';
import TextBubble from "./TextBubble";
import UpdateContactModal from '../modals/UpdateContactModal';
import AutoReplyModal from "../modals/AutoReplyModal";
import TextDraft from "../core/TextDraft";
import ArrowUpIcon from 'react-feather/dist/icons/chevron-up';
import ArrowDownIcon from 'react-feather/dist/icons/chevron-down';
import { ReactComponent as ReturnIcon } from '../icons/return.svg';
import { bindSetState } from "../helpers/React";

import './DialogHeader.scss';

class DialogHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        updateContactModalShown: false
      , autoReplyModalShown: false
      , autoReplyTextDraft: null
      , search: ''
    };

    this.searchChanged = this.searchChanged.bind(this);
    this.searchCleared = this.searchCleared.bind(this);
    this.showUpdateContactModal = bindSetState(this, { updateContactModalShown: true });
    this.hideUpdateContactModal = bindSetState(this, { updateContactModalShown: false });
    this.showAutoReplyModal = this.showAutoReplyModal.bind(this);
    this.hideAutoReplyModal = bindSetState(this, { autoReplyModalShown: false });
    this.findNext = this.find.bind(this, true);
    this.findPrevious = this.find.bind(this, false);
  }

  searchChanged(search) {
    this.setState({
      search
    });
  }

  searchCleared() {
    this.props.textSearch.cancel();
  }

  showAutoReplyModal() {
    const textDraft = new TextDraft();
    textDraft.text = this.props.combinedContact.autoReply || '';

    this.setState({
        autoReplyModalShown: true
      , autoReplyTextDraft: textDraft
    });
  }

  find(next) {
    const { textSearch, textNotFound } = this.props;
    const { search } = this.state;

    if (!textSearch.isSearching)
      textSearch.find(search, next, textNotFound);
  }

  render() {
    const { combinedContact, session, textSearch } = this.props;
    const { updateContactModalShown, autoReplyModalShown, autoReplyTextDraft } = this.state;
    const { autoReply } = combinedContact;

    return (
      <div className="dialog-header">
        <div className="name-container">
          <span>{combinedContact.name}</span>

          <TooltipButton tooltip="Update contact" className="edit-btn">
            <EditIcon size="20" className="icon-button" onClick={this.showUpdateContactModal} />
          </TooltipButton>
        </div>

        <div className="autoreply-container">
          {autoReply && <TextBubble text={autoReply} />}
        </div>

        <div className="autoreply-btn-container">
          <TooltipButton tooltip={(autoReply ? 'Change' : 'Set') + ' auto-reply'} className="autoreply-btn">
            <ReturnIcon width="20" height="20" className="icon-button" onClick={this.showAutoReplyModal} />
          </TooltipButton>
        </div>

        <div className="search-container">
          <SearchInput
            placeholder="Search"
            changed={this.searchChanged}
            cleared={this.searchCleared}
            enterKeyPressed={this.findPrevious}
            processing={textSearch.isSearching}
          />

          <TooltipButton tooltip="Previous occurrence">
            <ArrowUpIcon size="20" className="icon-button" onClick={this.findPrevious} />
          </TooltipButton>
          <TooltipButton tooltip="Next occurrence">
            <ArrowDownIcon size="20" className="icon-button" onClick={this.findNext} />
          </TooltipButton>
        </div>

        <UpdateContactModal
          session={session}
          shown={updateContactModalShown}
          hide={this.hideUpdateContactModal}
          combinedContact={combinedContact}
        />

        <AutoReplyModal
          session={session}
          shown={autoReplyModalShown}
          hide={this.hideAutoReplyModal}
          combinedContact={combinedContact}
          textDraft={autoReplyTextDraft}
        />
      </div>
    );
  }
}

export default observer(DialogHeader);