import React from 'react';
import ContactModal from './ContactModal';

class UpdateContactModal extends React.Component {
  constructor(props) {
    super(props);

    this.executeSubmitRequest = this.executeSubmitRequest.bind(this);
  }

  executeSubmitRequest({ name }) {
    return this.props.combinedContact.updateName(this.props.session.auth.token, name);
  }

  render() {
    const combinedContact = this.props.combinedContact;

    const initialValues = {
        phoneNumber: combinedContact.phoneNumber
      , name: ''
    };

    if (combinedContact.contact && combinedContact.contact.name)
      initialValues.name = combinedContact.contact.name;

    return (
      <ContactModal
        title="Update Contact"
        submitBtnTitle="Update"
        submitErrorText="Failed to update the contact"
        phoneNumberIsReadonly={true}
        shown={this.props.shown}
        hide={this.props.hide}
        initialValues={initialValues}
        session={this.props.session}
        executeSubmitRequest={this.executeSubmitRequest}
      />
    );
  }
}

export default UpdateContactModal;