import React, { Component } from 'react';
import HeaderView from '../components/HeaderView';
import ContactListView from '../components/ContactListView';
import WelcomeView from '../components/WelcomeView';
import DialogView from '../components/DialogView';
import { motion, AnimatePresence } from "framer-motion";

import './AuthorizedScreen.scss';

class AuthorizedScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCombinedContact: null
    };

    this.logOut = this.logOut.bind(this);
    this.contactSelected = this.contactSelected.bind(this);
  }

  logOut() {
    this.props.session.auth.logout()
      .catch(() => { });

    if (this.props.unauthorized)
      this.props.unauthorized();
  }

  contactSelected(combinedContact) {
    this.setState({
      selectedCombinedContact: combinedContact
    });
  }

  render() {
    const { session } = this.props;
    const { selectedCombinedContact } = this.state;

    const animProps = {
      initial: {
        opacity: 0
      }
      , animate: {
        opacity: 1
      }
      , exit: {
        opacity: 0
      }
      , transition: { duration: 0.2 }
    };

    return (
      <div className="wrapper">
        <div className="header-wrapper">
          <HeaderView session={session} logoClicked={this.contactSelected} logOutClicked={this.logOut} />
        </div>
        <div className="content-wrapper">
          <div className="contact-list-wrapper">
            <ContactListView session={session} selectedContact={selectedCombinedContact} contactSelected={this.contactSelected} />
          </div>
          <div className="page-wrapper">
            <AnimatePresence exitBeforeEnter>
              {selectedCombinedContact
                ? (<motion.div key="dialog" className="dialog-wrapper" {...animProps}>
                  <DialogView session={session} combinedContact={selectedCombinedContact} />
                </motion.div>)
                : (<motion.div key="welcome" {...animProps}>
                  <WelcomeView />
                </motion.div>)
              }
            </AnimatePresence>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthorizedScreen;
