import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import InputFiles from 'react-input-files';
import ImageIcon from 'react-feather/dist/icons/image';
import XIcon from 'react-feather/dist/icons/x';
import InfoModal from '../modals/InfoModal';
import ImageAttachment from '../core/ImageAttachment';
import { resizeImage, resizeIsSupported } from '../core/resizeImage';
import { maxImageFileSize, maxAttachmentSize } from '../Constants';
import { isUndefinedOrNull } from '../helpers/Object';
import { fileSizeToText } from '../helpers/File';
import TooltipButton from './TooltipButton';
import Spinner from "./Spinner";
import { bindSetState } from "../helpers/React";

import './AttachmentSelector.scss';

class AttachmentSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        infoModalTitle: ''
      , infoModalBody: null
      , infoModalShown: false
    };

    this.attachmentSelected = this.attachmentSelected.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.hideInfoModal = bindSetState(this, { infoModalShown: false });
  }

  showError(error) {
    this.setState({
        infoModalTitle: 'Attachment'
      , infoModalBody: error
      , infoModalShown: true
    });
  }

  attachmentSelected(files) {
    const { textDraft } = this.props;

    if (!files || !files.length || textDraft.processingAttachment)
      return;

    const file = files[0];
    const size = file.size;
    const mimeType = file.type;

    if (isUndefinedOrNull(size)) {
      this.showError('Failed to get the size of the selected file.');
      return;
    }

    if (size === 0) {
      this.showError('The selected file is empty.');
      return;
    }

    if (size > maxImageFileSize) {
      this.showError(`The selected file is ${fileSizeToText(size)} - the maximum supported size is ${fileSizeToText(maxImageFileSize)}.`);
      return;
    }

    if (size <= maxAttachmentSize) {
      textDraft.attachment = new ImageAttachment(file);
      return;
    }

    if (!resizeIsSupported(mimeType)) {
      this.showError(`The selected file is larger than ${fileSizeToText(maxAttachmentSize)} (the maximum attachment size) and automatic resizing of images of this format is not supported.`);
      return;
    }

    textDraft.processingAttachment = true;
    textDraft.attachment = null;

    resizeImage(file, maxAttachmentSize * 2 / 3, maxAttachmentSize)
      .then(({ blob, width, height }) => {
        textDraft.attachment = new ImageAttachment(blob, {
            name: file.name
          , width
          , height
        });

        textDraft.processingAttachment = false;
      })
      .catch(() => {
        textDraft.processingAttachment = false;

        if (textDraft === this.props.textDraft)
          this.showError(`The selected image is larger than ${fileSizeToText(maxAttachmentSize)} (the maximum attachment size) and resizing it to ${fileSizeToText(maxAttachmentSize)} has failed.`);
      });
  }

  removeAttachment() {
    const { textDraft } = this.props;

    if (!textDraft.processingAttachment)
      textDraft.attachment = null;
  }

  render() {
    const { textDraft: { attachment, processingAttachment } } = this.props;
    const { infoModalShown, infoModalTitle, infoModalBody } = this.state;

    return (
      <div className="attachment-selector">
        <InputFiles onChange={this.attachmentSelected} accept="image/*">
          <TooltipButton tooltip={!processingAttachment && (attachment ? 'Attach another image' : 'Attach image')}>
            <Button type="button" className="image-btn" color="primary" disabled={processingAttachment}>
              {processingAttachment ? <Spinner size="24" /> : ([<ImageIcon size="24" />, attachment ? (' ' + (attachment.name || 'Image selected')) : ''])}
            </Button>
          </TooltipButton>
        </InputFiles>

        <TooltipButton tooltip={!processingAttachment && attachment && 'Remove attachment'}>
          <Button className="remove-image-btn" disabled={processingAttachment || !attachment} color="primary" onClick={this.removeAttachment}><XIcon size="24" /></Button>
        </TooltipButton>

        <InfoModal shown={infoModalShown} title={infoModalTitle} hide={this.hideInfoModal}>
          {infoModalBody}
        </InfoModal>
      </div>
    );
  }
}

export default observer(AttachmentSelector);