import { observable, decorate } from 'mobx';
import { executeRequest } from '../core/Request.js';
import Text from './Text';
import { isDefinedAndNotNull, isUndefinedOrNull } from '../helpers/Object';

class DialogHistory {
  static textsPerBatch = 20;

  constructor(dialog) {
    this.dialog = dialog;
    this.texts = [];
    this.isFetching = false;
    this.hasFetched = false;
    this.allHistoryFetched = false;
  }

  fetchTexts(token, lessThanId) {
    if (this.isFetching || this.allHistoryFetched)
      return;

    this.isFetching = true;

    const limit = DialogHistory.textsPerBatch;

    const params = {
        sort: '-sent'
      , limit
    };

    if (this.hasFetched)
      lessThanId = this.texts.length ? this.texts[0].id : null;

    if (isDefinedAndNotNull(lessThanId))
      params['filter[and][a.id][lt]'] = lessThanId;

    executeRequest({
        request: this.dialog.getTextsRequestUrl()
      , token
      , params
      , responseIsArray: true
      , responseDataClass: Text
    })
      .then(texts => {
        texts.reverse();

        this.hasFetched = true;
        this.allHistoryFetched = texts.length < limit;

        this.texts.splice(0, 0, ...texts);
        this.isFetching = false;
      })
      .catch(e => {
        this.isFetching = false;
      });
  }

  textsWithoutUnreadTexts(unreadTexts) {
    const earliestUnreadTextId = unreadTexts.earliestUnreadTextId;

    return isUndefinedOrNull(earliestUnreadTextId)
      ? this.texts
      : this.texts.filter(t => { return t.id < earliestUnreadTextId; });
  }

  destroy() {
    Text.destroyTexts(this.texts);
  }
}

decorate(DialogHistory, {
    texts: observable
  , isFetching: observable
});

export default DialogHistory;