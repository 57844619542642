import React from 'react';
import TextEditorModal from "./TextEditorModal";
import TextTemplate from "../data/TextTemplate";

class AddTextTemplateModal extends React.Component {
  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);
    this.add = this.add.bind(this);
  }

  validate(text) {
    const { textTemplateList } = this.props.session;

    return textTemplateList.find(text)
        ? 'Template already exists'
        : null;
  }

  add() {
    const { textTemplateList, auth } = this.props.session;
    const { text } = this.props.textDraft;

    return TextTemplate.add(auth.token, { text })
      .then((textTemplate) => {
        textTemplateList.add(textTemplate);
      });
  }

  render() {
    const { shown, textDraft, hide } = this.props;

    return (
      <TextEditorModal
        shown={shown}
        textDraft={textDraft}
        title="Add Template"
        saveBtnTitle="Add"
        emptyError="Please enter the template"
        saveError="Failed to add the template"
        validate={this.validate}
        save={this.add}
        hide={hide}
      />
    );
  }
}

export default AddTextTemplateModal;