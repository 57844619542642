import loadImage from "../helpers/loadImage";
import { isDefinedAndNotNull } from "../helpers/Object";

class ImageAttachment {
  constructor(fileOrBlob, { name, width, height } = {}) {
    this.fileOrBlob = fileOrBlob;

    this.name = name || fileOrBlob.name;

    if (isDefinedAndNotNull(width) && isDefinedAndNotNull(height)) {
      this.width = width;
      this.height = height;
      this.dimensionsReady = true;
      return;
    }

    this.dimensionsReady = false;

    loadImage(fileOrBlob, ({ img, dispose, width, height }) => {
      if (img) {
        this.width = width;
        this.height = height;
        this.dimensionsReady = true;

        dispose();
      }
    });
  }
}

export default ImageAttachment;