import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AnnouncementListItemView from "../components/AnnouncementListItemView";
import ArrowLeftIcon from 'react-feather/dist/icons/arrow-left';
import AnnouncementDetailView from "../components/AnnouncementDetailView";
import { AnimatePresence, motion } from "framer-motion";
import { Scrollbars } from "react-custom-scrollbars";
import { bindSetState } from "../helpers/React";
import _object from "lodash/object";

import './AnnouncementsModal.scss';

class AnnouncementsModal extends React.Component {
  static backBtnAnimProps = {
    initial: {
        opacity: 0
      , x: -26
    }
    , animate: {
        opacity: 1
      , x: 0
    }
    , exit: {
        opacity: 0
      , x: -26
    }
    , transition: { duration: 0.15 }
  };

  static titleAnimProps = {
    variants: {
      initial: {
        x: 0
      }
      , shifted: {
        x: 26
      }
    }
    , transition: { duration: 0.15 }
  };

  static contentAnimProps = {
    initial: {
        opacity: 0
      , y: -10
    }
    , animate: {
        opacity: 1
      , y: 0
    }
    , exit: {
        opacity: 0
      , y: -10
    }
    , transition: { duration: 0.15 }
  };

  constructor(props) {
    super(props);

    const initialState = {
      selectedAnnouncement: null
    };

    this.state = _object.assign({}, initialState);

    this.hidden = bindSetState(this, initialState);
    this.showAnnouncements = bindSetState(this, { selectedAnnouncement: null });
    this.announcementSelected = this.announcementSelected.bind(this);
    this.hideClicked = this.hideClicked.bind(this);
  }

  hideClicked() {
    if (this.props.hide)
      this.props.hide();
  }

  announcementSelected(announcement) {
    this.setState({
      selectedAnnouncement: announcement
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevProps.shown !== this.props.shown) && this.props.shown) {
      const { session } = this.props;
      session.announcementList.refresh(session.auth.token);
    }
  }

  render() {
    const { selectedAnnouncement } = this.state;
    const { session } = this.props;
    const { announcementList } = session;

    return (
      <Modal isOpen={this.props.shown} toggle={this.hideClicked} onClosed={this.hidden} size="lg" modalClassName="announcements-modal">
        <ModalHeader toggle={this.hideClicked}>
          <AnimatePresence>
            {selectedAnnouncement && <motion.div key="back-btn" className="back-btn-container" {...AnnouncementsModal.backBtnAnimProps}>
               <ArrowLeftIcon size="22" className="icon-button" onClick={this.showAnnouncements} />
            </motion.div>}
          </AnimatePresence>
          <motion.div className="title-container" {...AnnouncementsModal.titleAnimProps} animate={selectedAnnouncement ? 'shifted' : 'initial'}>
            Announcements
          </motion.div>
        </ModalHeader>
        <ModalBody>
          <AnimatePresence exitBeforeEnter>
            {!selectedAnnouncement && <motion.div key="announcements" className="content" {...AnnouncementsModal.contentAnimProps}>
              <Scrollbars>
                {!announcementList.announcements.length
                  ? <div className="empty">No announcements were sent yet.</div>
                  : announcementList.announcements.map(announcement =>
                    <AnnouncementListItemView announcement={announcement} onViewDetailsClick={this.announcementSelected} />)}
              </Scrollbars>
            </motion.div>}

            {selectedAnnouncement && <motion.div key="announcement" className="content" {...AnnouncementsModal.contentAnimProps}>
              <Scrollbars>
                <AnnouncementDetailView session={session} announcement={selectedAnnouncement} />
              </Scrollbars>
            </motion.div>}
          </AnimatePresence>
        </ModalBody>
      </Modal>
    );
  }
}

export default AnnouncementsModal;