import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import LoginForm from '../components/LoginForm';
import Session from '../core/Session';
import { motion, AnimatePresence } from "framer-motion";
import Spinner from '../components/Spinner';
import updateDocumentTitle from '../core/updateDocumentTitle';
import { appTitle } from '../Constants';

import './UnauthorizedScreen.scss';

function getAnimProps(withAnimate) {
  const props = {
    exit: {
        opacity: 0
      , y: -10
    }
    , transition: { duration: 0.2 }
  };

  const animate = {
      opacity: 1
    , y: 0
  };

  if (!withAnimate)
    props.initial = animate;
  else {
    props.initial = {
        opacity: 0
      , y: -10
    };

    props.animate = animate;
  }

  return props;
}

class UnauthorizedScreen extends Component {
  static animPropsWithAnimate = getAnimProps(true);
  static animProps = getAnimProps();

  constructor(props) {
    super(props);

    this.state = {
        error: null
      , authorizing: false
    };

    this.loginFromSubmitted = this.loginFromSubmitted.bind(this);
  }

  loginFromSubmitted(credentials) {
    this.setState({
        error: null
      , authorizing: true
    });

    Session.authorize(credentials)
      .then(session => {
        const authorized = this.props.authorized;
        if (authorized)
          authorized(session);
      })
      .catch((error) => {
        //todo other errors

        this.setState({
            error: 'Invalid username and/or password'
          , authorizing: false
        });
      });
  }

  componentDidMount() {
    updateDocumentTitle();
  }

  render() {
    const { error } = this.state;

    return (
      <div className="unauthorized-screen">
        <div className="login-form-container">
          <AnimatePresence exitBeforeEnter>
            {this.state.authorizing
              ? (<motion.div key="authorizing" {...UnauthorizedScreen.animPropsWithAnimate}><Spinner size="28" /></motion.div>)
              : (<motion.div key="credentials" {...(error ? UnauthorizedScreen.animPropsWithAnimate : UnauthorizedScreen.animProps)}>
                <div className="info">Please sign in to {appTitle}</div>
                {error && (<Alert color="danger">{error}</Alert>)}
                <LoginForm submitted={this.loginFromSubmitted} />
              </motion.div>)}
          </AnimatePresence>
        </div>
      </div>
    );
  }
}

export default UnauthorizedScreen;
