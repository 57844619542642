import DataObject from "./DataObject";
import { executeRequest } from '../core/Request';
import { combineUrlParts } from '../helpers/Url';
import { moment, timezone } from '../helpers/moment';
import { observable, decorate } from 'mobx';
import _ from "lodash/collection";

class Announcement extends DataObject {
  static states = {
      sending: 'sending'
    , scheduled: 'scheduled'
    , canceled: 'canceled'
    , success: 'success'
    , partialSuccess: 'partial_success'
    , failure: 'failed'
  };

  static requestBase = 'announcements';

  static maxPhoneNumbers = 50;

  /*
  Returns the estimate in milliseconds
  */
  static estimateAddDuration(phoneNumberCount) {
    return (3 + phoneNumberCount * 2) * 1000;
  }

  constructor({ id, text, phoneNumbers, failedPhoneNumbers, state, scheduled } = {}) {
    super();

    this.id = id;
    this.text = text;
    this.phoneNumbers = phoneNumbers;
    this.failedPhoneNumbers = failedPhoneNumbers || [];
    this.state = state;
    this.scheduled = scheduled;

    this.isProcessing = false;
  }

  static fromRawData({ id, message, phonenumbers, failed_numbers, state, scheduled }) {
    return new Announcement({
        id: +id
      , text: message
      , phoneNumbers: phonenumbers
      , failedPhoneNumbers: failed_numbers
      , state: _.find(Announcement.states, (s) => s === state)
      , scheduled: scheduled ? moment.utc(scheduled).tz(timezone) : null
    });
  }

  static add(token, { text, phoneNumbers, scheduled }) {
    const data = {
        message: text
      , phonenumbers: phoneNumbers
    };

    if (scheduled)
      data.scheduled = scheduled.clone().utc().format('YYYY-MM-DD HH:mm:ss');

    return executeRequest({
        method: 'post'
      , request: this.requestBase
      , token
      , data
      , responseDataClass: this
    });
  }

  refresh(token) {
    this.isProcessing = true;

    return executeRequest({
        request: combineUrlParts(Announcement.requestBase, this.id)
      , token
      , responseDataClass: Announcement
    }).then((announcement) => {
      this.id = announcement.id;
      this.text = announcement.text;
      this.phoneNumbers = announcement.phoneNumbers;
      this.failedPhoneNumbers = announcement.failedPhoneNumbers;
      this.state = announcement.state;
      this.scheduled = announcement.scheduled;

      this.isProcessing = false;

      return this;
    })
    .catch(() => {
      this.isProcessing = false;
    });
  }

  cancel(token) {
    this.isProcessing = true;

    return executeRequest({
        method: 'delete'
      , request: combineUrlParts(Announcement.requestBase, this.id)
      , token
    }).then(() => {
      this.state = Announcement.states.canceled;

      this.isProcessing = false;
    })
    .catch(() => {
      this.isProcessing = false;
    });
  }
}

decorate(Announcement, {
    state: observable
  , isProcessing: observable
});

export default Announcement;