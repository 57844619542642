import React from 'react';
import TextEditorModal from "./TextEditorModal";

class AutoReplyModal extends React.Component {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
  }

  save() {
    const { combinedContact, session, textDraft } = this.props;

    return combinedContact.updateAutoReply(session.auth.token, textDraft.text);
  }

  remove() {
    const { combinedContact, session } = this.props;

    return combinedContact.updateAutoReply(session.auth.token, null);
  }

  render() {
    const { shown, textDraft, hide, combinedContact } = this.props;

    return (
      <TextEditorModal
        shown={shown}
        textDraft={textDraft}
        title="Auto-reply"
        emptyError="Please enter the auto-reply"
        saveError="Failed to save the auto-reply"
        removeError="Failed to remove the auto-reply"
        save={this.save}
        remove={combinedContact.autoReply && this.remove}
        hide={hide}
      />
    );
  }
}

export default AutoReplyModal;