import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import MiddleCenter from './MiddleCenter';
import { appTitle, logoUrl } from "../Constants";

import './WelcomeView.scss';

class WelcomeView extends React.Component {
  render() {
    return (
      <div className="welcome-view">
        <Scrollbars>
          <MiddleCenter>
            <div className="welcome">Welcome to {appTitle}!</div>
            <div className="text">Say hello to someone special today!</div>
            {logoUrl && <img className="logo" src={logoUrl}/>}
          </MiddleCenter>
        </Scrollbars>
      </div>
    );
  }
}

export default WelcomeView;