import { decorate, observable } from "mobx";

class TextDraft {
  constructor() {
    this.clear();
  }

  clear() {
    this.text = '';
    this.attachment = null;
    this.processingAttachment = false;
  }
}

decorate(TextDraft, {
    text: observable
  , attachment: observable
  , processingAttachment: observable
});

export default TextDraft;


