import React from 'react';
import { Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Col,
  Input,
  Alert
} from 'reactstrap';
import { Formik } from 'formik';
import SpinnerOverlay from  '../components/SpinnerOverlay';
import { trimAsString, stripNonDigits } from '../helpers/String';
import Contact from '../data/Contact';

class ContactModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        submitting: false
      , error: null
    };

    this.formik = React.createRef();

    this.submitClicked = this.submitClicked.bind(this);
    this.hideClicked = this.hideClicked.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.hidden = this.hidden.bind(this);

    this.validateValues = this.validateValues.bind(this);
    this.submit = this.submit.bind(this);
  }

  submitClicked() {
    this.formik.current.submitForm();
  }

  hideClicked() {
    if (!this.state.submitting && this.props.hide)
      this.props.hide();
  }

  keyDown(event) {
    if (event.key === 'Enter')
      this.submitClicked();
  }

  hidden() {
    this.formik.current.resetForm();

    this.setState({
        submitting: false
      , error: null
    });
  }

  validateValues({ phoneNumber, name }) {
    let errors = {};

    if (!this.props.phoneNumberIsReadonly) {
      const { isValid, isEmpty, noDigits, invalidLength, requiredLength, doesNotStartWith1, presentInContactList } =
        Contact.validatePhoneNumber(phoneNumber, this.props.session.combinedContactList);

      if (!isValid)
        errors.phoneNumber = isEmpty
          ? 'Please enter the phone number'
          : (noDigits
            ? 'The entered phone number does not contain digits'
            : (doesNotStartWith1
              ? 'Only phone numbers starting with "1" are supported'
              : (presentInContactList
                ? 'This number is already in your contact list'
                : (!invalidLength
                  ? 'The entered phone number has invalid format'
                  : (invalidLength < requiredLength
                    ? `Only ${invalidLength} out of the required ${requiredLength} digits entered`
                    : `${invalidLength} digits entered - should be ${requiredLength} digits`)))));
    }

    const { isValid, invalidLength, maxLength } = Contact.validateName(name, false);

    if (!isValid)
      errors.name = `${invalidLength} characters entered - only up to ${maxLength} characters are allowed`;

    return errors;
  }

  submit({ phoneNumber, name }, { setSubmitting }) {
    this.setState({
      submitting: true
    });

    phoneNumber = stripNonDigits(phoneNumber);
    name = trimAsString(name);

    this.props.executeSubmitRequest({ phoneNumber, name })
      .then(() => {
        setSubmitting(false);

        this.setState({
          submitting: false
        }, () => {
          this.hideClicked();
        });
      })
      .catch(error => {
        setSubmitting(false);

        this.setState({
            submitting: false
          , error: this.props.submitErrorText
        });
      });
  }

  render() {
    const { submitting, error } = this.state;

    return (
      <Modal isOpen={this.props.shown} toggle={this.hideClicked} onOpened={this.shown} onClosed={this.hidden}>
        <ModalHeader toggle={this.hideClicked}>{this.props.title}</ModalHeader>
        <ModalBody>
          {error && (<Alert color="danger">{error}</Alert>)}
          <Formik
            ref={this.formik}
            initialValues={this.props.initialValues}
            validate={this.validateValues}
            onSubmit={this.submit}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup row>
                  <Label sm={4}>Phone Number</Label>
                  <Col sm={8}>
                    <Input name="phoneNumber"
                      readOnly={this.props.phoneNumberIsReadonly}
                      plaintext={this.props.phoneNumberIsReadonly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={this.keyDown}
                      value={values.phoneNumber}
                      className={errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''}
                    />
                    {errors.phoneNumber && touched.phoneNumber && (<div className="input-error">{errors.phoneNumber}</div>)}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={4}>Name <span className="note">(optional)</span></Label>
                  <Col sm={8}>
                    <Input name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={this.keyDown}
                      value={values.name}
                      className={errors.name && touched.name ? 'is-invalid' : ''}
                    />
                    {errors.name && touched.name && (<div className="input-error">{errors.name}</div>)}
                  </Col>
                </FormGroup>

                {submitting && (<SpinnerOverlay />)}
              </form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.submitClicked} disabled={submitting}>{this.props.submitBtnTitle}</Button>{' '}
          <Button color="secondary" onClick={this.hideClicked} disabled={submitting}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ContactModal;