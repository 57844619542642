import React from 'react';
import { observer } from 'mobx-react';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import XIcon from 'react-feather/dist/icons/x';
import { Scrollbars } from 'react-custom-scrollbars';
import TextDraft from "../core/TextDraft";
import RemoveTextTemplateModal from "../modals/RemoveTextTemplateModal";
import AddTextTemplateModal from "../modals/AddTextTemplateModal";
import { bindSetState } from "../helpers/React";
import { trimAsString } from "../helpers/String";
import _ from "lodash/math";

import './TextTemplatesDropdown.scss';

class TextTemplatesDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        addTemplateModalShown: false
      , textDraftToBeAdded: null
      , removeTemplateModalShown: false
      , textTemplateToBeRemoved: null
    };

    this.hideInfoModal = bindSetState(this, { infoModalShown: false });
    this.hideAddTemplateModal = bindSetState(this, { addTemplateModalShown: false });
    this.hideRemoveTemplateModal = bindSetState(this, { removeTemplateModalShown: false });
    this.addToTemplates = this.addToTemplates.bind(this);
  }

  addToTemplates() {
    const textDraft = new TextDraft();
    textDraft.text = trimAsString(this.props.textDraft.text);

    this.setState({
        addTemplateModalShown: true
      , textDraftToBeAdded: textDraft
    });
  }

  removeTemplate(textTemplate) {
    this.setState({
        removeTemplateModalShown: true
      , textTemplateToBeRemoved: textTemplate
    });
  }

  render() {
    const { session, textTemplateSelected } = this.props;
    const { textTemplates } = session.textTemplateList;

    const { addTemplateModalShown, textDraftToBeAdded, removeTemplateModalShown, textTemplateToBeRemoved  } = this.state;

    let { text } = this.props.textDraft;
    text = trimAsString(text);

    let items = null;

    if (textTemplates.length) {
      items = [<DropdownItem divider/>];

      items.push(<div style={{width: '280px', height: (_.min([textTemplates.length, 8]) * 41) + 'px'}}>
        <Scrollbars>
          {textTemplates.map((textTemplate) => (
            <DropdownItem className="text-template" onClick={(event) => {
              if (!event.isDefaultPrevented())
                textTemplateSelected(textTemplate.text);
            }}>
              {textTemplate.text}
              <XIcon size="15" onClick={(event) => {
                event.preventDefault();
                this.removeTemplate(textTemplate);
              }}/>
            </DropdownItem>))}
        </Scrollbars>
      </div>);
    }

    return (
      <UncontrolledButtonDropdown className="text-templates-dropdown" direction="up">
        <DropdownToggle caret color="primary">
          Templates
        </DropdownToggle>
        <DropdownMenu className="text-templates">
          <DropdownItem onClick={this.addToTemplates}>Add template</DropdownItem>
          {items}
        </DropdownMenu>

        <AddTextTemplateModal
          session={session}
          textDraft={textDraftToBeAdded}
          shown={addTemplateModalShown}
          hide={this.hideAddTemplateModal}
        />

        <RemoveTextTemplateModal
          session={session}
          textTemplate={textTemplateToBeRemoved}
          shown={removeTemplateModalShown}
          hide={this.hideRemoveTemplateModal}
        />
      </UncontrolledButtonDropdown>
    );
  }
}

export default observer(TextTemplatesDropdown);