import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { observer } from 'mobx-react';
import { Scrollbars } from 'react-custom-scrollbars';
import PlusIcon from 'react-feather/dist/icons/plus-circle';
import FileIcon from 'react-feather/dist/icons/file-text';
import SearchInput from "./SearchInput";
import Text from '../data/Text';
import TooltipButton from './TooltipButton';
import ContactListItemView from './ContactListItemView';
import NewTextNotifications from '../core/NewTextNotifications';
import AddContactModal from '../modals/AddContactModal';
import ImportContactsModal from "../modals/ImportContactsModal";
import { isDefinedAndNotNull } from '../helpers/Object';
import updateDocumentTitle from '../core/updateDocumentTitle';
import { bindSetState } from "../helpers/React";
import { trimAsString } from "../helpers/String";
import _ from "lodash/collection";

import './ContactListView.scss';

class ContactListView extends React.Component {
  static pollTextsInterval = 5000;

  static itemAnimProps = {
    initial: {
        opacity: 0
      , y: -5
    }
    , animate: {
        opacity: 1
      , y: 0
    }
    , exit: {
        opacity: 0
      , y: -5
    }
    , transition: { duration: 0.2 }
  };

  constructor(props) {
    super(props);

    this.state = {
        addContactModalShown: false
      , importContactsModalShown: false
      , search: ''
      , viewActive: true
    };

    this.scrollbars = React.createRef();
    this.newTextNotifications = new NewTextNotifications();

    this.searchChanged = this.searchChanged.bind(this);
    this.contactSelected = this.contactSelected.bind(this);
    this.showAddContactModal = bindSetState(this, { addContactModalShown: true });
    this.showImportContactsModal = bindSetState(this, { importContactsModalShown: true });
    this.hideAddContactModal = bindSetState(this, { addContactModalShown: false });
    this.hideImportContactsModal = bindSetState(this, { importContactsModalShown: false });
    this.showActive = bindSetState(this, { viewActive: true });
    this.showArchived = bindSetState(this, { viewActive: false });
    this.polledTextProcessed = this.polledTextProcessed.bind(this);
    this.contactAdded = this.contactAdded.bind(this);
    this.contactsAdded = this.contactsAdded.bind(this);

    this.windowFocus = this.windowFocus.bind(this);
  }

  searchChanged(search) {
    this.setState({
      search
    });
  }

  contactSelected(combinedContact) {
    if (this.props.contactSelected)
      this.props.contactSelected(combinedContact);
  }

  contactAdded(combinedContact) {
    this.showActive();

    this.scrollbars.current.scrollToTop();

    this.contactSelected(combinedContact);
  }

  contactsAdded(combinedContacts) {
    this.contactAdded(combinedContacts[0]);
  }

  windowFocus() {
    this.ensureNewTextNotificationsHidden();
  }

  componentDidMount() {
    this.scheduleNextPollTexts();

    this.ensureNewTextNotificationsHidden();

    window.addEventListener('focus', this.windowFocus, false);
  }

  componentWillUnmount() {
    this.stopPollTexts();

    window.removeEventListener('focus', this.windowFocus, false);
  }

  ensureNewTextNotificationsHidden() {
    if (this.props.selectedContact)
      this.newTextNotifications.ensureHidden(this.props.selectedContact);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.session !== this.props.session) {
      this.newTextNotifications.ensureAllHidden();
      return;
    }

    if (prevState.viewActive !== this.state.viewActive)
      this.scrollbars.current.scrollTop(1); //scrollTop(1) updates the scrollbar, scrollToTop() and update() do not

    if (prevProps.selectedContact !== this.props.selectedContact)
      this.ensureNewTextNotificationsHidden();
  }

  scheduleNextPollTexts() {
    this.pollTextsTimeoutId = setTimeout(() => {
      this.pollTexts();
    }, ContactListView.pollTextsInterval);
  }

  stopPollTexts() {
    this.pollTextsStopped = true;

    if (isDefinedAndNotNull(this.pollTextsTimeoutId))
      clearTimeout(this.pollTextsTimeoutId);
  }

  polledTextProcessed(polledText, combinedContact, isNew) {
    if (!isNew
      || !polledText.isIncoming
      || ((this.props.selectedContact === combinedContact) && document.hasFocus()))
      return;

    this.newTextNotifications.show(polledText, combinedContact, () => {
      try {
        window.focus();
      }
      catch (e) { }

      this.contactSelected(combinedContact);
    });
  }

  pollTexts() {
    Text.poll(this.props.session.auth.token)
      .then(polledTexts => {
        if (this.pollTextsStopped)
          return;

        this.props.session.combinedContactList.processPolledTexts(polledTexts, this.polledTextProcessed);

        this.scheduleNextPollTexts();
      })
      .catch(error => {
        if (this.pollTextsStopped)
          return;

        this.scheduleNextPollTexts();
      });
  }

  updateDocumentTitle() {
    let unreadCount = 0;

    this.props.session.combinedContactList.combinedContacts.forEach(c => {
      unreadCount += c.unreadTexts.incomingCount;
    });

    updateDocumentTitle(unreadCount);
  }

  render() {
    this.updateDocumentTitle();

    const { selectedContact, session } = this.props;
    const { search, viewActive, importContactsModalShown, addContactModalShown } = this.state;
    const processedSearch = trimAsString(search).toLowerCase();

    const combinedContacts =  _.filter(session.combinedContactList.combinedContacts, ({ archived, name, phoneNumber }) =>
      (archived === !viewActive)
      && (!processedSearch || (name.toLowerCase().indexOf(processedSearch) >= 0) || (phoneNumber.indexOf(processedSearch) >= 0)));

    return (
      <div className="contact-list">
        <div className="list-header">
          <div className="inner">
            <SearchInput placeholder="Find Contacts" changed={this.searchChanged} />

            <TooltipButton tooltip="Import from CSV">
              <FileIcon size="20" className="icon-button" onClick={this.showImportContactsModal} />
            </TooltipButton>
            <TooltipButton tooltip="Add contact">
              <PlusIcon size="20" className="icon-button" onClick={this.showAddContactModal} />
            </TooltipButton>

            <ImportContactsModal
              session={session}
              shown={importContactsModalShown}
              hide={this.hideImportContactsModal}
              contactsAdded={this.contactsAdded}
            />
            <AddContactModal
              session={session}
              shown={addContactModalShown}
              hide={this.hideAddContactModal}
              contactAdded={this.contactAdded}
            />
          </div>
        </div>
        <div className="contacts-container">
          <Scrollbars ref={this.scrollbars}>
            <AnimatePresence>
              {combinedContacts.map(combinedContact =>
                <motion.div key={combinedContact.generatedId} {...ContactListView.itemAnimProps}>
                  <ContactListItemView
                    combinedContact={combinedContact}
                    session={session}
                    selected={this.contactSelected}
                    isActive={selectedContact === combinedContact}
                  />
                </motion.div>)}
            </AnimatePresence>
          </Scrollbars>
        </div>
        <div className="list-footer">
          <a className={viewActive ? 'selected' : ''} onClick={this.showActive}>Active</a>
          <a className={!viewActive ? 'selected' : ''} onClick={this.showArchived}>Archived</a>
        </div>
      </div>
    );
  }
}

export default observer(ContactListView);